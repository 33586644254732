import type { ApiJob } from '@typings/api-job'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type CreatedJob = {
	id: string
	onSuccess: (job: ApiJob) => void
}

type ApiJobStoreState = {
	createdJobs: CreatedJob[]
	handledJobIds: string[]
	enableJobsQuery: boolean

	setEnableJobsQuery: (enable: boolean) => void
	setCreatedJobs: (createdJobs: CreatedJob[]) => void
	setHandledJobIds: (handledJobIds: string[]) => void

	reset: () => void
}

const apiJobStoreInitialState = {
	createdJobs: [],
	handledJobIds: [],
	enableJobsQuery: false,
}

export const useApiJobsStore = createWithEqualityFn<ApiJobStoreState>((set) => {
	return {
		...apiJobStoreInitialState,

		setEnableJobsQuery: (enable) => set({ enableJobsQuery: enable }),
		setCreatedJobs: (createdJobs) => set({ createdJobs }),
		setHandledJobIds: (handledJobIds) => set({ handledJobIds }),

		reset: () => set(apiJobStoreInitialState),
	}
}, shallow)
