import IconButton from '@mui/material/IconButton'
import Slide, { type SlideProps } from '@mui/material/Slide'
import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar'
import RoundCheckmark from '@static/common/round-checkmark.svg?react'
import ErrorIcon from '@static/common/snack-error.svg?react'
import CloseIcon from '@static/common/times.svg?react'
import WarningIcon from '@static/issues/open-issue.svg?react'
import { useCommonStore } from '@stores/useCommonStore'
import type { SnackBarType } from '@typings/snackbar'
import type React from 'react'
import { type CSSProperties, type FC, Fragment } from 'react'

const Transition = (props: SlideProps) => <Slide {...props} direction='left' />

const getTypeProps = (type: SnackBarType) => {
	switch (type) {
		case 'success':
			return {
				icon: RoundCheckmark,
				color: 'var(--green-3)',
			}

		case 'info':
			return {
				icon: WarningIcon,
				color: 'var(--tertiary-3)',
			}

		case 'error':
			return {
				icon: ErrorIcon,
				color: 'var(--red-1)',
			}

		default:
			return {}
	}
}

const SnackBarElement: FC = () => {
	const { snackBarData, closeSnackBar } = useCommonStore((state) => ({
		snackBarData: state.snackBarData,
		closeSnackBar: state.closeSnackBar,
	}))

	const handleClose = (e: Event | React.SyntheticEvent, reason: SnackbarCloseReason) => {
		if (reason === 'clickaway' || reason === 'escapeKeyDown') return
		closeSnackBar()
	}

	const { color, icon } = getTypeProps(snackBarData.type)
	const Icon = icon

	return (
		<Snackbar
			classes={{ root: 'snackbar-element' }}
			style={{ '--bar-clr': color } as CSSProperties}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			TransitionComponent={Transition}
			transitionDuration={150}
			autoHideDuration={3000}
			disableWindowBlurListener // If true, the autoHideDuration timer will expire even if the window is not focused.
			open={!!snackBarData.message}
			onClose={handleClose}
			message={
				<Fragment>
					{Icon && <Icon className='snackbar-element__icon' />}
					<span className='snackbar-element__text'>{snackBarData.message}</span>
				</Fragment>
			}
			action={
				<Fragment>
					<IconButton className='close-btn' size='small' color='inherit' onClick={(e) => handleClose(e, null)}>
						<CloseIcon />
					</IconButton>
				</Fragment>
			}
		/>
	)
}

export default SnackBarElement
