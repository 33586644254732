import { IconButtonElement } from '@components/Common/IconButtonElement/IconButtonElement'
import CloseIcon from '@static/common/times.svg?react'
import WarningIcon from '@static/issues/open-issue.svg?react'
import './BannerMsgStrip.scss'
import type { FC, SyntheticEvent } from 'react'

interface NotificationStripProps {
	message: string
	showBanner: boolean
	onClick: (e: SyntheticEvent) => void
}

export const BannerMsgStrip: FC<NotificationStripProps> = ({ message, showBanner, onClick }) => {
	return (
		<>
			{showBanner && (
				<div className='banner-strip'>
					<WarningIcon />
					<h6 className='banner-strip__text'>{message}</h6>

					<IconButtonElement onClick={onClick} className='banner-strip__cta'>
						<CloseIcon />
					</IconButtonElement>
				</div>
			)}
		</>
	)
}
