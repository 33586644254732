import AdminIcon from '@static/user/admin.svg?react'
import AnalystIcon from '@static/user/analyst.svg?react'
import type { FC } from 'react'
import { type TUserRole, USER_ROLE } from '../../../enums/users'
import './UserTypeIcon.scss'

interface IUserTypeIconProps {
	userType: TUserRole
	iconClr?: string
}

export const UserTypeIcon: FC<IUserTypeIconProps> = ({ userType, iconClr }) => {
	const isAdmin = userType === USER_ROLE.analyst

	const color = iconClr ? { color: iconClr } : {}

	return (
		<div className='user-type-icon'>{isAdmin ? <AdminIcon style={color} /> : <AnalystIcon style={color} />}</div>
	)
}
