import { ASSET_TYPE } from '@enums/assets'
import type { Routes } from '@enums/routes'
import type { UIError } from '@typings/generics'
import type { AxiosError } from 'axios'
import { format } from 'date-fns'
import qs, { type ParsedQs } from 'qs'
import type { KeyboardEvent } from 'react'
import type { InferType, Schema } from 'yup'

export const isObjEmpty = (obj: Record<string, any>): boolean => {
	return !obj || !Object.values(obj).some((x) => x !== null && x !== '')
}

export const isNill = (v: any) => v === null || v === undefined

// Creates a new cleaned object with no null, undefined, or empty string values
export const cleanObj = <T extends Record<string, any> | any[]>(obj: T): T => {
	if (isNill(obj)) return obj

	if (Array.isArray(obj)) {
		return obj
			.filter((item) => item !== undefined && item !== null && item !== '')
			.map((item) => (typeof item === 'object' ? cleanObj(item) : item)) as T
	}

	return Object.entries(obj).reduce((cleanedObj, [key, value]) => {
		if (value === undefined || value === null || value === '') return cleanedObj

		const typedKey = key as keyof T

		if (typeof value === 'object') {
			const cleanedValue = cleanObj(value)
			if (Array.isArray(cleanedValue) && cleanedValue.length > 0) {
				cleanedObj[typedKey] = cleanedValue as any
			} else if (!Array.isArray(cleanedValue) && Object.keys(cleanedValue).length > 0) {
				cleanedObj[typedKey] = cleanedValue
			}
		} else {
			cleanedObj[typedKey] = value
		}

		return cleanedObj
	}, {} as T)
}

export const formatDate = (date: string | 'today', dateFormat = 'dd/MM/yyyy') => {
	if (isNill(date)) return

	if (date === 'today') return format(new Date(), dateFormat)

	return format(new Date(date), dateFormat)
}

export const currentRoute = (): Routes => {
	const path = window.location.pathname.split('/')[1]
	return `/${path}` as Routes
}

export const handleSubmitByEnter = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, callBackFn: any) => {
	const isDroppingLine = e.key === 'Enter' && e.shiftKey
	if (isDroppingLine) return

	if (e.key === 'Enter') {
		callBackFn()
		e.preventDefault()
	}
}

// LocalStorage
export const getLS = <T>(key: string): T => JSON.parse(localStorage.getItem(key))
export const setLS = <T = any>(key: string, value: T) => localStorage.setItem(key, JSON.stringify(value))
export const removeLS = (key: string) => localStorage.removeItem(key)

export const cleanItemId = (id: string | number): number => {
	if (typeof id === 'number') return id

	if (id?.includes('-')) {
		return +id.split('-')[1]
	}
	return +id
}

export const capitilizeFirstLetters = (str: string, onlyFirstLetter?: boolean): string => {
	if (!str) return

	if (onlyFirstLetter) return str.charAt(0).toUpperCase() + str.slice(1)

	return str
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

export const replaceUnderscore = (value: string): string => {
	if (!value) return

	return value.includes('_') ? value.replaceAll('_', ' ') : value
}

export const extractQueryParams = <T = Record<string, any>>(search: string): T => {
	if (!search) return {} as T

	const urlQueryParams = qs.parse(search, { ignoreQueryPrefix: true })
	return urlQueryParams as T
}

export const extractUIError = (e: AxiosError<any>): string => {
	if (!e) return

	const errObj: UIError = e.response?.data?.detail

	if (!errObj || !errObj?.show) return

	if (errObj?.msg && errObj?.show) return errObj.msg
}

export const redirectToWorkSpace = (workspace: string, redirectToSpecificRoute?: string) => {
	const { pathname } = window.location

	const baseUrl =
		import.meta.env.MODE === 'development'
			? `http://${workspace}.localhost:3000`
			: `https://${workspace}.${import.meta.env.VITE_FE_BASE_URL}`

	if (baseUrl.includes('undefined')) return

	const finalUrl = redirectToSpecificRoute ? `${baseUrl}${redirectToSpecificRoute}` : `${baseUrl}${pathname}`

	window.location.href = finalUrl
}

export const boolStrToBool = (value: string): boolean => {
	if (value !== 'true' && value !== 'false') return Boolean(value)

	return value === 'true'
}

export const formatUrlParams = (params: Record<string, any> | string = '', search?: string): string => {
	const url = Object.values(params)
		.filter((param) => !isNill(param) && param !== '' && param !== 0)
		.map((param) => `/${param}`)
		.join('')

	const searchQuery = search ? `?${search}` : ''

	return `${url}${searchQuery}`
}

export const convertDataToPercentages = <T>(props: {
	data: T[]
	valueKey: keyof T
	minPercentage?: number
}): Array<T & { value: number; rawPercentage: number }> => {
	const { data, valueKey, minPercentage = 5 } = props
	const getCount = (item: T) => Number(item[valueKey])

	const nonEmptyDatapoints = data.filter((item) => getCount(item) !== 0)

	// If there is only one data point, return it with 100% value
	if (nonEmptyDatapoints.length === 1)
		return nonEmptyDatapoints.map((item) => ({ ...item, value: 100, rawPercentage: 100 }))

	const sum = data.map((item) => getCount(item)).reduce((acc, item) => acc + item, 0)

	// Calculate the raw percentages
	const rawPercentages = data.map((item) => {
		const count = getCount(item)
		const percentage = (count / sum) * 100
		return { ...item, rawPercentage: percentage }
	})

	// Calculate total raw percentage and total minimum percentage
	const totalRawPercentage = rawPercentages.reduce((acc, item) => acc + item.rawPercentage, 0)
	const totalMinPercentage = minPercentage * rawPercentages.length

	// Calculate the remaining percentage after allocating minPercentage to each data point
	const remainingPercentage = Math.max(0, 100 - totalMinPercentage)

	// Calculate the final percentages
	const datasets = rawPercentages.map((item) => {
		// Calculate the ratio of this item's raw percentage to the total raw percentage
		const ratio = item.rawPercentage / totalRawPercentage

		// Distribute the remaining percentage among data points proportionally to their ratios
		const value = minPercentage + ratio * remainingPercentage
		return { ...item, value: getCount(item) === 0 ? 0 : Math.round(value) }
	})

	return datasets
}

export const hideString = (key: string) => {
	if (!key) return

	return key
		.split('')
		.map(() => '*')
		.join('')
}

export const isNetblock = (type: number) => type === ASSET_TYPE.Netblock

export const downloadLink = (url: string, options?: { filename?: string; target?: string }) => {
	const { filename, target } = options || {}

	const anchor = Object.assign(
		document.createElement('a'),
		cleanObj({
			href: url,
			download: filename,
			target,
			rel: target === '_blank' ? 'noopener noreferrer' : undefined,
		}),
	)

	anchor.click()
	anchor.remove()
	URL.revokeObjectURL(url)
}

export const sanitizeQueryParamsToFilters = <TSchema extends Schema>(
	queryParams: ParsedQs,
	yupSchema: TSchema,
) => {
	if (!queryParams || isObjEmpty(queryParams)) return {}

	// Clean and validate the query parameters using the schema
	const cleanQueryParams = yupSchema.cast(queryParams, { stripUnknown: true })

	// Extract date parameters separately (I don't want them in the filters object)
	const { from, to, on, ...restParams } = cleanQueryParams

	// Construct the filters object without date parameters
	const filters = Object.entries(restParams).reduce(
		(acc, [key, value]) => {
			if (value !== undefined && value !== '') {
				acc[key] = value
			}
			return acc
		},
		{} as InferType<typeof yupSchema>,
	)

	if (from && to) {
		filters.dates = [{ key: on, from_date: from, to_date: to }]
	}

	return filters
}
