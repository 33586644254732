import { type InferType, number, object, string } from 'yup'

const baseScheme = {
	first_name: string()
		.required('First name is required')
		.max(40, 'First name must be less than 40 characters')
		.typeError('First name is required')
		.trim(),

	last_name: string()
		.required('Last name is required')
		.max(40, 'Last name must be less than 40 characters')
		.typeError('Last name is required')
		.trim(),

	email: string().required('Email is required').email('Please insert a valid email').trim(),
}

export const updateUserFormScheme = object(baseScheme)

export const addUserFormScheme = object({
	...baseScheme,
	role_id: number().required('Role is required').typeError('Role is required'),
})

export type AddUserFormScheme = InferType<typeof addUserFormScheme>
export type UpdateUserFormScheme = InferType<typeof updateUserFormScheme>
