import { ROUTES } from '@enums/routes'
import { TIMEFRAME, TIMEFRAME_TO_STRING, defaultTimeFrame } from '@pages/Dashboard/dashboard-consts'
import ChevronDown from '@static/common/chevron-down.svg?react'
import { extractQueryParams } from '@utils/global-helpers'
import { type FC, type SyntheticEvent, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { type PopoverItem, PopoverMenu } from '../../../Common/PopoverMenu/PopoverMenu'
import { TopNavButton } from '../TopNav'

export const TimeFrameSelect: FC = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement>(null)
	const { search } = useLocation()
	const history = useHistory()

	const timeFrame = useMemo(() => {
		const queryParam = extractQueryParams(search)
		return queryParam?.t ? TIMEFRAME_TO_STRING[queryParam.t as string] : TIMEFRAME_TO_STRING[defaultTimeFrame]
	}, [search])

	const handleTimeFrameClick = (e: SyntheticEvent, newTimeFrame: PopoverItem) => {
		if (newTimeFrame === timeFrame) return
		const formattedTimeFrame = newTimeFrame.name.replaceAll(' ', '_').toUpperCase()

		history.push(`${ROUTES.DASHBOARD}?t=${TIMEFRAME[formattedTimeFrame]}`)
		setAnchorEl(null)
	}

	const dropDownList = Object.values(TIMEFRAME_TO_STRING).map((t) => ({ name: t, value: t }))

	return (
		<div className='timeframe-wrapper'>
			<TopNavButton onClick={(e) => setAnchorEl(e.currentTarget)} isActive={!!anchorEl}>
				{timeFrame}
				<ChevronDown className='chevron' />
			</TopNavButton>

			<PopoverMenu
				activeOptionValues={[timeFrame]}
				handleCloseMenu={() => setAnchorEl(null)}
				darkMode
				anchorEl={anchorEl}
				options={dropDownList}
				onOptionClick={handleTimeFrameClick}
			/>
		</div>
	)
}
