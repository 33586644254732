import type { SnackBarData } from '@typings/snackbar'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type CommonStoreState = {
	// Snackbar

	snackBarData: SnackBarData
	setSnackBarData: (data: SnackBarData) => void

	toggleSnackBar: (data: SnackBarData) => void
	closeSnackBar: () => void
}

const commonStoreInitialState = {
	snackBarData: {} as SnackBarData,
}

export const useCommonStore = createWithEqualityFn<CommonStoreState>((set, get) => {
	return {
		...commonStoreInitialState,

		setSnackBarData: (data) => set({ snackBarData: data }),

		toggleSnackBar: (data) => get().setSnackBarData(data),

		closeSnackBar: () => get().setSnackBarData({} as SnackBarData),
	}
}, shallow)
