import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import clsx from 'clsx'
import type React from 'react'
import type { ComponentProps, FC } from 'react'
import './AuthContainer.scss'

type AuthContainerProps = {
	children: React.ReactNode
	className?: string
	flexContainerProps?: Partial<ComponentProps<typeof FlexContainer>>
}

export const AuthContainer: FC<AuthContainerProps> = ({ children, className, flexContainerProps }) => {
	return (
		<FlexContainer className={clsx('auth-container', className)} {...flexContainerProps}>
			{children}
		</FlexContainer>
	)
}
