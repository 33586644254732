export const QUERY_KEYS = {
	// Globals
	configOptions: 'configOptions',
	appInit: 'appInit',
	apiJobs: 'apiJobs',

	// Dashboard
	dashboard: 'dashboard',
	securityScoreOvertime: 'securityScoreOvertime',

	// Auth
	checkInvitationToken: 'checkInvitationToken',
	checkResetPwdToken: 'checkResetPwdToken',
	getEnableMFAData: 'getEnableMFAData',

	// Settings
	apiKey: 'apiKey',
	ssoData: 'ssoData',
	integrations: 'integrations',

	// Issues
	issuesTable: 'issuesTable',
	issueGroups: 'issueGroups',
	issueRetestData: 'issueRetestData',
	issueEvidence: 'issueEvidence',
	issueHistory: 'issueHistory',
	issueUsersAssignment: 'issueUsersAssignment',
	issuesFilterValues: 'issuesFilterValues',

	// DNS
	dnsTable: 'dnsTable',

	// Creds
	credentialsTable: 'credentialsTable',
	credsCounters: 'credsCounters',
	credsFilterValues: 'credsFilterValues',

	// Techs
	techsGroupTable: 'techsGroupTable',
	techsHostsTable: 'techsHostsTable',
	techsFilterValues: 'techsFilterValues',

	// Cves
	cvesHeader: 'cvesHeader',
	cvesTable: 'cvesTable',
	cveDetails: 'cveDetails',
	patchingTable: 'patchingTable',
	cvesFilterValues: 'cvesFilterValues',

	// Orgs
	orgsHeader: 'orgsHeader',
	orgsTable: 'orgsTable',

	// Assets
	assetsTable: 'assetsTable',
	assetsCounters: 'assetsCounters',
	assetPanelAlerts: 'assetPanelAlerts',
	assetPanelVulns: 'assetPanelVulns',
	assetPanelDns: 'assetPanelDns',
	assetPanelHttp: 'assetPanelHttp',
	assetPanelTechs: 'assetPanelTechs',
	assetConnections: 'assetConnections',
	assetHistory: 'assetHistory',
	assetsFilterValues: 'assetsFilterValues',
	assetTagsPortMapping: 'assetTagsPortMapping',

	// Netblocks
	netblocks: 'netblocks',

	// Creds
	credsSmartSearchAutocomplete: 'credsSmartSearchAutocomplete',
	credsSmartSearchQueryList: 'credsSmartSearchQueryList',

	// Smart Search
	smartSearchQueryList: 'smartSearchQueryList',
	smartSearchAutocomplete: 'smartSearchAutocomplete',

	// General | Shared
	assetDiscoveryGraph: 'assetDiscoveryGraph',
	tagOptions: 'tagOptions',
	itemComments: 'itemComments', // Assets and Issues both have comments
	users: 'users',
} as const

export type QueryKeys = (typeof QUERY_KEYS)[keyof typeof QUERY_KEYS]
