import ElementLogo from '@static/common/element-logo-full.svg?react'
import type { FC } from 'react'
import { ButtonElement } from '../ButtonElement/ButtonElement'
import { FlexContainer } from '../FlexContainer/FlexContainer'

type ErrorBoundaryProps = {
	errorText?: string
	onClick?: () => void
}

export const ErrorBoundaryComp: FC<ErrorBoundaryProps> = ({ errorText, onClick }) => {
	const handleClick = () => {
		if (onClick) return onClick()
		window.location.reload()
	}

	const text = errorText ? errorText : 'Oops, something went wrong. Please try again.'

	return (
		<FlexContainer className='error-boundary' align='center' justify='center' direction='column' gap='var(--s-xs)'>
			<ElementLogo />

			<FlexContainer direction='column' gap='var(--s-xs)'>
				<p className='error-boundary--title'>{text}</p>
				<p className='error-boundary--subtitle'>Our team has been notified.</p>

				<ButtonElement variant='secondary' onClick={handleClick} iconProps={{ icon: 'restart' }} scaleOnTap>
					Try Again
				</ButtonElement>
			</FlexContainer>
		</FlexContainer>
	)
}
