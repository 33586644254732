import { axiosInstance } from '@services/axios'
import { returnData } from '@services/http-helpers'
import type { ConfigOptionsResponse } from '@typings/config-options'
import type { DataResponse, MessageResponse } from '@typings/generics'
import type { AddOrgPayload, AddOrgResp, Organization, UpdateOrgPayload } from '@typings/organization'
import type { ItemsResponseWithTotal } from '@typings/table'
import type { OrganizationsTableItem, TOrganizationsHeader } from '@typings/table-organization'
import type { User } from '@typings/user'
import type { AxiosRequestConfig } from 'axios/dist/axios'
import type { GenerateAPIKeyRes, GetAPIKeyRes } from '../../typings/api-key'
import { INIT_ROUTES, ORGANIZATION_ROUTES, ORGS } from '../services-consts'

export type AppInitResp = {
	banner_msg: string | null
	organizations: Organization[]
	user: User
	is_sso_auth: boolean
}

class OrganizationServiceClass {
	addOrganization = async (orgId: number, orgData: AddOrgPayload) => {
		return axiosInstance.post<DataResponse<AddOrgResp>>(ORGS(orgId), orgData).then(returnData)
	}

	reactivateOrganization = async (props: {
		targetOrgId: number
		currentOrgId: number
	}): Promise<MessageResponse> => {
		const { targetOrgId, currentOrgId } = props
		return axiosInstance
			.put(ORGANIZATION_ROUTES.REACTIVATE_ORG(currentOrgId), { target_org_id: targetOrgId })
			.then(returnData)
	}

	deactivateOrganization = async (props: {
		targetOrgId: number
		currentOrgId: number
	}): Promise<MessageResponse> => {
		const { targetOrgId, currentOrgId } = props
		return axiosInstance
			.put(ORGANIZATION_ROUTES.DEACTIVATE_ORG(currentOrgId), { target_org_id: targetOrgId })
			.then(returnData)
	}

	updateOrganization = async (orgId: number, orgData: UpdateOrgPayload): Promise<any> => {
		return axiosInstance.put(ORGANIZATION_ROUTES.UPDATE_ORG(orgId), orgData).then(returnData)
	}

	onAppInit = async (signal: AbortSignal): Promise<AppInitResp> => {
		return axiosInstance.get(INIT_ROUTES.BASE, { signal }).then(returnData)
	}

	getConfigurationOptions = async (): Promise<ConfigOptionsResponse> => {
		return axiosInstance.get(ORGANIZATION_ROUTES.CONFIG_OPTIONS).then(returnData)
	}

	getOrgsTable = async (orgId: number, params: AxiosRequestConfig) => {
		return axiosInstance
			.get<ItemsResponseWithTotal<OrganizationsTableItem>>(ORGANIZATION_ROUTES.ORGANIZATIONS_SCREEN(orgId), params)
			.then(returnData)
	}

	getOrgsHeader = async (orgId: number, config?: AxiosRequestConfig) => {
		return axiosInstance
			.get<TOrganizationsHeader>(ORGANIZATION_ROUTES.ORGANIZATIONS_HEADER(orgId), config)
			.then(returnData)
	}

	getAPIKey = async (orgId: number, config?: AxiosRequestConfig): Promise<GetAPIKeyRes> => {
		return axiosInstance.get(ORGANIZATION_ROUTES.API(orgId), config).then(returnData)
	}

	generateAPIKey = async (orgId: number): Promise<GenerateAPIKeyRes> => {
		return axiosInstance.post(ORGANIZATION_ROUTES.API_GENERATE(orgId)).then(returnData)
	}

	removeAPIKey = async (props: { orgId: number; apiKeyId: number }): Promise<GenerateAPIKeyRes> => {
		const { orgId, apiKeyId } = props
		return axiosInstance.delete(ORGANIZATION_ROUTES.API_KEY_ID(orgId, apiKeyId)).then(returnData)
	}

	updateOrgSettings = async (orgId: number, newSettings: Record<string, any>): Promise<any> => {
		return axiosInstance.post(ORGANIZATION_ROUTES.UPDATE_ORG_SETTINGS(orgId), newSettings).then(returnData)
	}
}

export const OrganizationService = new OrganizationServiceClass()
