export const ISSUE_STATUS = {
	open: 1,
	triaged: 2,
	closed: 3,
} as const

export type TIssueStatus = (typeof ISSUE_STATUS)[keyof typeof ISSUE_STATUS]

export const ISSUE_STATUS_TO_LABEL = {
	[ISSUE_STATUS.open]: 'open',
	[ISSUE_STATUS.triaged]: 'triaged',
	[ISSUE_STATUS.closed]: 'closed',
}

const ISSUE_CLOSURE_REASON = {
	resolved: 1,
	false_positive: 2,
	irrelevant: 3,
	auto_closed: 5,
}

export type TClosureReason = (typeof ISSUE_CLOSURE_REASON)[keyof typeof ISSUE_CLOSURE_REASON]

export const ISSUE_REASON_TO_LABEL = {
	[ISSUE_CLOSURE_REASON.false_positive]: 'False positive',
	[ISSUE_CLOSURE_REASON.irrelevant]: 'Irrelevant',
	[ISSUE_CLOSURE_REASON.resolved]: 'Resolved',
	[ISSUE_CLOSURE_REASON.auto_closed]: 'Auto Closed',
}

export const ISSUE_SEVERITY = {
	critical: 1,
	high: 2,
	medium: 3,
	low: 4,
	info: 5,
} as const

export type TIssueSeverity = (typeof ISSUE_SEVERITY)[keyof typeof ISSUE_SEVERITY]

export const ISSUE_SEVERITY_TO_LABEL = {
	[ISSUE_SEVERITY.info]: 'info',
	[ISSUE_SEVERITY.low]: 'low',
	[ISSUE_SEVERITY.medium]: 'medium',
	[ISSUE_SEVERITY.high]: 'high',
	[ISSUE_SEVERITY.critical]: 'critical',
} as const

export const ISSUE_RETEST_STATUS = {
	pending: 1,
	secure: 2,
	vulnerable: 3,
	failed: 4,
}

export type TRestestStatus = (typeof ISSUE_RETEST_STATUS)[keyof typeof ISSUE_RETEST_STATUS]

export const ISSUE_RETEST_STATUS_TO_COLOR = {
	[ISSUE_RETEST_STATUS.pending]: 'rgb(237 171 0)',
	[ISSUE_RETEST_STATUS.secure]: 'rgb(43 201 172)',
	[ISSUE_RETEST_STATUS.vulnerable]: '#DB575E',
	[ISSUE_RETEST_STATUS.failed]: '#DB575E',
}

export const ISSUE_RETEST_STATUS_TO_BG = {
	[ISSUE_RETEST_STATUS.pending]: 'rgb(247 238 210)',
	[ISSUE_RETEST_STATUS.secure]: 'rgb(210 255 247 / 62%)',
	[ISSUE_RETEST_STATUS.vulnerable]: '#F8DDDF',
	[ISSUE_RETEST_STATUS.failed]: '#F8DDDF',
}

export const ISSUE_VIEWS = {
	TABLE: 'table',
	LIST: 'list',
} as const

export type IssueViewType = (typeof ISSUE_VIEWS)[keyof typeof ISSUE_VIEWS]

export const issueStatuses = [
	{
		value: ISSUE_STATUS.open,
		name: 'Open',
	},
	{
		value: ISSUE_STATUS.triaged,
		name: 'Triaged',
	},
	{
		value: ISSUE_STATUS.closed,
		name: 'Closed',
	},
]

export const issueClosureReasons = [
	{
		value: ISSUE_CLOSURE_REASON.resolved,
		name: 'Resolved',
	},
	{
		value: ISSUE_CLOSURE_REASON.false_positive,
		name: 'False Positive',
	},
	{
		value: ISSUE_CLOSURE_REASON.irrelevant,
		name: 'Irrelevant',
	},
]
