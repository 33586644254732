import CircularLoader from '@components/Common/CircularLoader'
import BrokenChainLink from '@static/common/broken-chainlink.svg?react'
import RoundTimesIcon from '@static/common/round-times.svg?react'
import VerifiedIcon from '@static/common/validated.svg?react'
import type { ApiJobType } from '@typings/api-job'
import { ASSET_TYPE, type TAssetType } from './assets'

// Status

export const API_JOB_STATUS = {
	PENDING: 1,
	SUCCESS: 2,
	FAILURE: 3,
	TIMEOUT: 4,
} as const

export const API_JOB_STATUS_TO_TOOLTIP = {
	[API_JOB_STATUS.PENDING]: 'Task in progress, file will be downloaded once it is complete',
	[API_JOB_STATUS.SUCCESS]: 'Task finished successfully',
	[API_JOB_STATUS.FAILURE]: 'Task failed due to an error, please try again later',
	[API_JOB_STATUS.TIMEOUT]: 'Task timed out, please try again later',
}

export const API_JOB_STATUS_TO_COMP = {
	[API_JOB_STATUS.PENDING]: <CircularLoader loading color='var(--tertiary-2)' />,
	[API_JOB_STATUS.SUCCESS]: <VerifiedIcon className='checkmark-icon active' />,
	[API_JOB_STATUS.TIMEOUT]: <BrokenChainLink />,
	[API_JOB_STATUS.FAILURE]: <RoundTimesIcon className='round-times-icon active' />,
}

// Type

export const API_JOB_TYPE = {
	VULNS_CSV: 1,
	ISSUES_CSV: 2,
	DNS_CSV: 3,
	CREDS_CSV: 4,
	ALL_ASSETS_CSV: 5,
	DOMAINS_CSV: 6,
	SUBDOMAINS_CSV: 7,
	CLOUD_ASSETS_CSV: 8,
	CERTIFICATES_CSV: 9,
	IPS_CSV: 10,
	INTEGRATION_MSG_RETEST: 11,
	PDF_REPORT: 12,
} as const

export const CSV_JOB_TYPE_TO_FILENAME: Partial<Record<ApiJobType, string>> = {
	[API_JOB_TYPE.VULNS_CSV]: 'Patch Management',
	[API_JOB_TYPE.ISSUES_CSV]: 'Issues',
	[API_JOB_TYPE.DNS_CSV]: 'DNS',
	[API_JOB_TYPE.CREDS_CSV]: 'Credentials',
	[API_JOB_TYPE.ALL_ASSETS_CSV]: 'All Assets',
	[API_JOB_TYPE.DOMAINS_CSV]: 'Domains',
	[API_JOB_TYPE.SUBDOMAINS_CSV]: 'Subdomains',
	[API_JOB_TYPE.CLOUD_ASSETS_CSV]: 'Cloud Assets',
	[API_JOB_TYPE.CERTIFICATES_CSV]: 'Certificates',
	[API_JOB_TYPE.IPS_CSV]: 'IPs',
	[API_JOB_TYPE.PDF_REPORT]: 'Attack Surface Report',
}

export const ASSET_TYPE_TO_JOB: Partial<Record<TAssetType, ApiJobType>> = {
	[ASSET_TYPE.All]: API_JOB_TYPE.ALL_ASSETS_CSV,
	[ASSET_TYPE.Cloud]: API_JOB_TYPE.CLOUD_ASSETS_CSV,
	[ASSET_TYPE.Domain]: API_JOB_TYPE.DOMAINS_CSV,
	[ASSET_TYPE.Subdomain]: API_JOB_TYPE.SUBDOMAINS_CSV,
	[ASSET_TYPE.Certificate]: API_JOB_TYPE.CERTIFICATES_CSV,
	[ASSET_TYPE.IpAsset]: API_JOB_TYPE.IPS_CSV,
	[ASSET_TYPE.Netblock]: API_JOB_TYPE.IPS_CSV,
}

export const JOB_TO_NAME: Partial<Record<ApiJobType, string>> = {
	[API_JOB_TYPE.INTEGRATION_MSG_RETEST]: 'Integration Test Notification',
}
