import { ThemeProvider } from '@emotion/react'
import { MAINTENANCE_CODE } from '@enums/generics'
import { ROUTES } from '@enums/routes'
import { MaintenanceApp } from '@pages/Maintenance/Maintenance'
import { AppRouter } from '@routing/AppRouter'
import { AuthService } from '@services/apis/auth'
import { axiosInstance } from '@services/axios'
import { useUserStore } from '@stores/useUserStore'
import { type QueryClient, useQueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type FC, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { muiTheme } from './theme'
import './App.scss'

const setupMaintenanceInterceptor = (
	replace: (path: string) => void,
	isMaintenance: { current: boolean },
	queryClient: QueryClient,
) => {
	axiosInstance.interceptors.response.use(
		(res) => res,
		(err) => {
			const code = err?.response?.status

			if (code === MAINTENANCE_CODE) {
				isMaintenance.current = true
				replace(ROUTES.MAINTENANCE)
				queryClient.removeQueries() // So that all queries are re-executed on next login
				return Promise.reject(err)
			}

			return Promise.reject(err)
		},
	)
}

export const App: FC = () => {
	const isMaintenance = useRef<boolean>(false)

	const { replace } = useHistory()
	const { pathname } = useLocation()

	const setIsAuthenticated = useUserStore((s) => s.setIsAuthenticated)
	const queryClient = useQueryClient()

	useEffect(() => {
		const checkSession = async () => {
			try {
				await AuthService.checkSession()
				setIsAuthenticated(true)
			} catch (err) {
				console.log(err)
				// Only redirect to login page if the current route is not an auth route
				if (!pathname.includes(ROUTES.AUTH)) replace(ROUTES.LOGIN)
				setIsAuthenticated(false)
			}
		}

		setupMaintenanceInterceptor(replace, isMaintenance, queryClient)
		checkSession()
	}, [])

	if (isMaintenance.current) return <MaintenanceApp />

	return (
		<div className='app'>
			<ThemeProvider theme={muiTheme}>
				<AppRouter />
				<ReactQueryDevtools position='right' buttonPosition='top-left' />
			</ThemeProvider>
		</div>
	)
}
