import type { BaseDropDownItem } from '@components/Common/SimpleDropDown/SimpleDropDown'
import AdminIcon from '@static/user/admin.svg?react'
import AnalystIcon from '@static/user/analyst.svg?react'
import startCase from 'lodash/startCase'

export const USER_ROLE = {
	admin: 1,
	analyst: 2,
} as const

export type TUserRole = (typeof USER_ROLE)[keyof typeof USER_ROLE]

const USER_ROLE_TO_LABEL = {
	[USER_ROLE.admin]: 'admin',
	[USER_ROLE.analyst]: 'analyst',
}

export const userRolesOptions: BaseDropDownItem[] = [
	{
		label: startCase(USER_ROLE_TO_LABEL[USER_ROLE.admin]),
		value: USER_ROLE.admin,
		icon: <AdminIcon />,
	},
	{
		label: startCase(USER_ROLE_TO_LABEL[USER_ROLE.analyst]),
		value: USER_ROLE.analyst,
		icon: <AnalystIcon />,
	},
]
