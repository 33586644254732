import { QueryClient } from '@libs/useQuery'
import { init as SentryInit, browserTracingIntegration, replayIntegration } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './pages/App/App'
import './styles/core/_index.scss'

class DebuggedBrowserRouter extends BrowserRouter {
	constructor(props) {
		super(props)

		if (import.meta.env.MODE === 'production') return

		// @ts-ignore
		console.log(`Initial path is: ${this.history.location.pathname}`)

		// @ts-ignore
		this.history.listen((location, action) => {
			// @ts-ignore
			console.log(`History changed => Path: "${location.pathname}"; Action: "${action?.toLowerCase()}"`)
		})
	}
}

SentryInit({
	enabled: import.meta.env.ENV === 'prod',
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.ENV,
	integrations: [browserTracingIntegration(), replayIntegration()],
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	replaysSessionSampleRate: 1.0, //  Capture 100% of the sessions
})

const root = createRoot(document.getElementById('root'))

root.render(
	<QueryClientProvider client={QueryClient}>
		<DebuggedBrowserRouter>
			<App />
		</DebuggedBrowserRouter>
	</QueryClientProvider>,
)
