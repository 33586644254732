import ErrorBoundaryComp from '@components/Common/ErrorBoundaryComp'
import type React from 'react'
import type { FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route, type RouteProps } from 'react-router-dom'

type CustomRouteProps = {
	component: React.ReactNode
	guardProps?: {
		allowEntry: boolean
		redirectTo: string
	}
} & Omit<RouteProps, 'component'>

export const CustomRoute: FC<CustomRouteProps> = ({ component, guardProps, ...routeProps }) => {
	if (guardProps && !guardProps.allowEntry) return <Redirect to={guardProps.redirectTo} />

	return (
		<Route {...routeProps}>
			<ErrorBoundary FallbackComponent={ErrorBoundaryComp as FC}>{component}</ErrorBoundary>
		</Route>
	)
}
