import ChevronDown from '@static/common/chevron-down.svg?react'
import CopyLinkIcon from '@static/common/copy-link.svg?react'
import CopyIcon from '@static/common/copy.svg?react'
import DownloadIcon from '@static/common/download.svg?react'
import FileIcon from '@static/common/file.svg?react'
import PenIcon from '@static/common/pen.svg?react'
import PlusIcon from '@static/common/plus.svg?react'
import RestartIcon from '@static/common/restart.svg?react'
import RoundCheckmark from '@static/common/round-checkmark.svg?react'
import TrashIcon from '@static/common/trash.svg?react'
import ExclamationIcon from '@static/issues/open-issue.svg?react'
import clsx from 'clsx'
import { type ButtonHTMLAttributes, type FC, type SVGProps, forwardRef, useMemo } from 'react'
import './ButtonElement.scss'

type PredefinedIcons =
	| 'checkmark'
	| 'warning'
	| 'file'
	| 'download'
	| 'trash'
	| 'plus'
	| 'edit'
	| 'copyLink'
	| 'copy'
	| 'restart'

type IconProps = {
	icon: PredefinedIcons | FC<SVGProps<SVGSVGElement>>
	circular?: boolean
}

type ConditionalProps =
	| {
			children: React.ReactNode
			iconProps?: IconProps
	  }
	| {
			children?: never
			iconProps: IconProps
	  }

type ButtonElementProps = ConditionalProps & {
	variant?: 'primary' | 'danger' | 'select' | 'secondary'
	disabled?: boolean
	scaleOnTap?: boolean
	className?: string
	isActive?: boolean
	fullWidth?: boolean
	style?: React.CSSProperties
} & ButtonHTMLAttributes<HTMLButtonElement>

export const ButtonElement = forwardRef<HTMLButtonElement, ButtonElementProps>(
	(
		{
			variant = 'primary',
			children,
			disabled,
			scaleOnTap,
			fullWidth,
			iconProps = {} as IconProps,
			style,
			isActive,
			className,
			...btnProps
		},
		ref,
	) => {
		const Icon = useMemo(() => {
			if (!iconProps.icon) return null

			switch (iconProps.icon) {
				case 'checkmark':
					return RoundCheckmark
				case 'warning':
					return ExclamationIcon
				case 'file':
					return FileIcon
				case 'download':
					return DownloadIcon
				case 'trash':
					return TrashIcon
				case 'plus':
					return PlusIcon
				case 'edit':
					return PenIcon
				case 'copyLink':
					return CopyLinkIcon
				case 'copy':
					return CopyIcon
				case 'restart':
					return RestartIcon
				default:
					return iconProps.icon
			}
		}, [iconProps])

		return (
			<button
				ref={ref}
				className={clsx('button-element', {
					active: isActive,
					'scale-on-tap': scaleOnTap && !disabled,
					'full-width': fullWidth,
					[variant]: variant,
					[className]: className,
				})}
				disabled={disabled}
				style={style}
				{...btnProps}
			>
				{Icon && <Icon className={clsx('button-element__icon', { circular: iconProps.circular })} />}

				{children}

				{variant === 'select' && <ChevronDown className='button-element__chevron' />}
			</button>
		)
	},
)
