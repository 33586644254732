import AppLayout from '@components/AppLayout'
import Loader from '@components/Common/Loader'
import { OrgMFAEnforcementModal } from '@components/OrgNotificationModals/OrgMFAEnforcementModal'
import { OrgsWorkspaceModal } from '@components/OrgNotificationModals/OrgsWorkspaceModal'
import { DEFAULT_APP_ROUTES, ROUTES } from '@enums/routes'
import loadable from '@loadable/component'
import type { AppInitResp } from '@services/apis/organization'
import { axiosInstance } from '@services/axios'
import { useCommonStore } from '@stores/useCommonStore'
import { useOrgStore } from '@stores/useOrgStore'
import { useUserStore } from '@stores/useUserStore'
import { useQueryClient } from '@tanstack/react-query'
import { type FC, useEffect } from 'react'
import { Redirect, Switch, useHistory } from 'react-router-dom'
import { CustomRoute } from './CustomRoute'

const Dashboard = loadable(() => import('@pages/Dashboard'))
const Organizations = loadable(() => import('@pages/Organizations'))
const Issues = loadable(() => import('@pages/Issues'))
const Assets = loadable(() => import('@pages/Assets/Assets').then((m) => ({ default: m.Assets })))
const Dns = loadable(() => import('@pages/DNS'))
const Technologies = loadable(() => import('@pages/Technologies'))
const Cves = loadable(() => import('@pages/Cves'))
const CredsBreach = loadable(() => import('@pages/CredsBreach'))
const Settings = loadable(() => import('@pages/Settings'))
const AddOrg = loadable(() => import('@pages/AddOrg'))

const {
	DASHBOARD,
	ORGANIZATIONS,
	ISSUES,
	ASSETS,
	DNS: DNSRoute,
	SETTINGS,
	ADD_ORG,
	TECHNOLOGIES,
	PATCHING,
	CREDENTIALS,
} = ROUTES

type AuthenticatedRoutesProps = {
	appInitData: AppInitResp
}

export const AuthenticatedRoutes: FC<AuthenticatedRoutesProps> = ({ appInitData }) => {
	const { replace } = useHistory()

	const { allowCredsBreachRoute, organization } = useOrgStore((s) => ({
		allowCredsBreachRoute: s.allowCredsBreachRoute,
		organization: s.organization,
	}))

	const { user, logoutUser } = useUserStore((s) => ({
		logoutUser: s.logoutUser,
		user: s.user,
	}))

	const queryClient = useQueryClient()

	useEffect(() => {
		const interceptor = axiosInstance.interceptors.response.use(
			(res) => res,
			(err) => {
				const code = err?.response?.status

				// Redirect to login page if the user is already in AuthenticatedRoutes
				if (code === 401) {
					logoutUser(replace, queryClient)
					const toggleSnackBar = useCommonStore.getState().toggleSnackBar
					toggleSnackBar({
						message: 'Your session has expired. Please login again.',
						type: 'info',
					})

					return Promise.reject(err)
				}

				return Promise.reject(err)
			},
		)

		return () => axiosInstance.interceptors.response.eject(interceptor) // So we don't listen to this interceptor when a user logged out
	}, [])

	if (!organization) return <OrgsWorkspaceModal appInitData={appInitData} />

	const showMFAEnforcementModal =
		organization?.settings.enforce_mfa && !user?.mfa_enabled && !appInitData.is_sso_auth

	if (showMFAEnforcementModal) return <OrgMFAEnforcementModal appInitData={appInitData} /> // MFA enforcement modal

	return (
		<AppLayout>
			<Switch>
				<CustomRoute exact path={DASHBOARD} component={<Dashboard />} />

				<CustomRoute exact path={`${ORGANIZATIONS}/:page?`} component={<Organizations />} />

				<CustomRoute exact path={`${ISSUES}/:viewType?`} component={<Issues fallback={<Loader loading />} />} />

				<CustomRoute
					exact
					path={`${ASSETS}/:type/:status?/:page?`}
					component={<Assets fallback={<Loader loading />} />}
				/>

				<CustomRoute exact path={`${DNSRoute}/:page?`} component={<Dns fallback={<Loader loading />} />} />

				<CustomRoute exact path={TECHNOLOGIES} component={<Technologies fallback={<Loader loading />} />} />

				<CustomRoute path={PATCHING} component={<Cves fallback={<Loader loading />} />} />

				<CustomRoute
					exact
					path={`${CREDENTIALS}/:status?/:page?`}
					component={<CredsBreach fallback={<Loader loading />} />}
					guardProps={{ allowEntry: allowCredsBreachRoute, redirectTo: DEFAULT_APP_ROUTES.DASHBOARD }}
				/>

				<CustomRoute path={SETTINGS} component={<Settings fallback={<Loader loading />} />} />

				<CustomRoute path={ADD_ORG} component={<AddOrg fallback={<Loader loading />} />} />

				<CustomRoute component={<Redirect to={DEFAULT_APP_ROUTES.DASHBOARD} />} path='*' />
			</Switch>
		</AppLayout>
	)
}
