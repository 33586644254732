import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import StyledTextField from '@components/Common/StyledTextField'
import { type FC, useRef } from 'react'
import './VerifyMFAInputs.scss'

type VerifyMFAInputsProps = {
	setInputValues: React.Dispatch<React.SetStateAction<string[]>>
	inputValues: string[]
}

export const VerifyMFAInputs: FC<VerifyMFAInputsProps> = ({ setInputValues, inputValues }) => {
	const inputRefs = useRef<Array<HTMLInputElement | null>>([])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
		const value = e.target.value
		if (value.length > 1) return

		setInputValues((prev) => {
			const newValues = [...prev]
			newValues[idx] = value
			return newValues
		})

		if (value.length === 1 && idx < 5) {
			inputRefs.current[idx + 1]?.focus()
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
		if (e.key === 'Backspace' && !inputValues[idx] && idx > 0) {
			inputRefs.current[idx - 1]?.focus()
		}
	}

	return (
		<FlexContainer gap='var(--s-xxs)' className='verify-mfa-inputs'>
			{inputValues.map((_, idx) => (
				<StyledTextField
					key={idx}
					type='number'
					inputProps={{ style: { textAlign: 'center' } }}
					autoFocus={idx === 0}
					inputRef={(el) => (inputRefs.current[idx] = el)}
					value={inputValues[idx]}
					onKeyDown={(e) => handleKeyDown(e, idx)}
					onChange={(e) => handleInputChange(e, idx)}
				/>
			))}
		</FlexContainer>
	)
}
