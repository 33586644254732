import type { SortData } from '@typings/table'

export enum ACTIVITY_STATUS {
	ACTIVE = 1,
	INACTIVE = 2,
}

export const ACTIVITY_STATUS_TO_LABEL = {
	[ACTIVITY_STATUS.ACTIVE]: 'Active',
	[ACTIVITY_STATUS.INACTIVE]: 'Inactive',
}

export const DEFAULT_ROWS_PER_PAGE = 20 as const

export const CERT_EXPIRY_THRESHOLD = 14

export const EMPTY_TABLE_CELL_CLASS = 'empty-cell'

export const DEFAULT_TABLE_SORT: SortData = {
	sort_by: 'created_on',
	sort_direction: 'desc',
}

export const MAINTENANCE_CODE = 503 as const

export enum BREAKPOINTS {
	LG = 2480,
	DEFAULT = 1840,
}

export const BUCKETS = {
	techs: 'https://tech-icons.s3.fr-par.scw.cloud',
	flags: 'https://country-flags.s3.fr-par.scw.cloud',
	orgIcons: 'https://organisations-icons.s3.fr-par.scw.cloud',
	integrations: 'https://integration-icons.s3.fr-par.scw.cloud',
	cloudProviders: 'https://cloud-providers.s3.fr-par.scw.cloud',
} as const
