import { UserAvatar } from '@components/Common/UserAvatar/UserAvatar'
import { ROUTES, type Routes } from '@enums/routes'
import { useAppInit } from '@hooks/useAppInit'
import { useUserStore } from '@stores/useUserStore'
import clsx from 'clsx'
import { type FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TimeFrameSelect from './TimeFrameSelect'
import TopNavOrgs from './TopNavOrgs'
import TopNavReport from './TopNavReport'
import UserPopover from './UserPopover'
import './TopNav.scss'

type TopNavButtonProps = {
	children: React.ReactNode
	isActive?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export const TopNavButton: FC<TopNavButtonProps> = ({ children, isActive, ...props }) => {
	return (
		<button {...props} className={clsx('top-nav__button', props.className, { active: isActive })}>
			{children}
		</button>
	)
}

const routesWithTopNav = [ROUTES.ORGANIZATIONS, ROUTES.PATCHING] as Routes[]

export const TopNav: FC = () => {
	const [userAnchorEl, setAnchorEl] = useState<HTMLElement>(null)

	const { pathname } = useLocation()
	const pathnameRoute = `/${pathname.split('/')[1]}`

	const fullName = useUserStore((s) => s.fullName)
	const { data: appInitData } = useAppInit()

	return (
		<nav className={clsx('top-nav', { radius: !routesWithTopNav.includes(pathnameRoute as Routes) })}>
			<TopNavOrgs appInitData={appInitData} />

			<div className='top-nav__right'>
				{pathnameRoute === ROUTES.DASHBOARD && <TimeFrameSelect />}

				<TopNavReport />

				<div className='divider' />

				<TopNavButton onClick={(e) => setAnchorEl(e.currentTarget)}>
					<UserAvatar circleSize={24} name={fullName} color='var(--tertiary-3)' />
				</TopNavButton>

				<UserPopover
					anchorEl={userAnchorEl}
					closePopover={() => setAnchorEl(null)}
					fullName={fullName}
					appInitData={appInitData}
				/>
			</div>
		</nav>
	)
}
