import { axiosInstance } from '@services/axios'
import type { MessageResponse } from '@typings/generics'
import type { UserResponse, UsersResponse } from '@typings/user'
import type { UpdateUserFormScheme } from '@yup/add-user'
import type { AxiosRequestConfig } from 'axios/dist/axios'
import { returnData } from '../http-helpers'
import { BASE_API_ROUTES, USERS_ROUTES } from '../services-consts'

class UsersServiceClass {
	getUsers = async (orgId: number, config?: AxiosRequestConfig) => {
		return axiosInstance.get<UsersResponse>(BASE_API_ROUTES.USERS(orgId), config).then(returnData)
	}

	deleteUser = async (orgId: number, userId: number) => {
		return axiosInstance.delete<UserResponse>(`${BASE_API_ROUTES.USERS(orgId)}/${userId}`).then(returnData)
	}

	updateUser = async (orgId: number, userId: number, updatedUser: UpdateUserFormScheme) => {
		return axiosInstance.put<UserResponse>(USERS_ROUTES.UPDATE(orgId, userId), updatedUser).then(returnData)
	}

	updateRole = async (
		orgId: number,
		userId: number,
		updatedRole: { role_id: number },
	): Promise<MessageResponse> => {
		return axiosInstance.put(USERS_ROUTES.UPDATE_ROLE(orgId, userId), updatedRole).then(returnData)
	}

	deactivateUser = async (orgId: number, userId: number) => {
		return axiosInstance.post<UserResponse>(USERS_ROUTES.DEACTIVATE(orgId, userId)).then(returnData)
	}

	activateUser = async (orgId: number, userId: number) => {
		return axiosInstance.post<UserResponse>(USERS_ROUTES.ACTIVATE(orgId, userId)).then(returnData)
	}
}

export const UsersService = new UsersServiceClass()
