import { EnableMFAModal } from '@components/Common/EnableMFAModal/EnableMFAModal'
import Modal from '@components/Common/Modal'
import { PopoverMenu } from '@components/Common/PopoverMenu/PopoverMenu'
import type { PopoverItem } from '@components/Common/PopoverMenu/PopoverMenu'
import TooltipElement from '@components/Common/TooltipElement'
import { UserTypeIcon } from '@components/Common/UserTypeIcon/UserTypeIcon'
import { QUERY_KEYS } from '@enums/queries'
import { USER_ROLE } from '@enums/users'
import loadable from '@loadable/component'
import { MFAService } from '@services/apis/mfa'
import type { AppInitResp } from '@services/apis/organization'
import { UsersService } from '@services/apis/users'
import LogoutIcon from '@static/common/logout.svg?react'
import DisableMFAIcon from '@static/issues/open-issue.svg?react'
import KeyIcon from '@static/sidemenu/credentials-icon.svg?react'
import UserIcon from '@static/user/admin.svg?react'
import { useCommonStore } from '@stores/useCommonStore'
import { useOrgStore } from '@stores/useOrgStore'
import { useUserStore } from '@stores/useUserStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getMfaModalNotes } from '@utils/getMfaModalNotes'
import { type UpdateUserFormScheme, updateUserFormScheme } from '@yup/add-user'
import { type FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

interface UserPopoverProps {
	fullName: string
	anchorEl: HTMLElement
	appInitData: AppInitResp
	closePopover: () => void
}

type PopoverKeys = 'logout' | 'enable_mfa' | 'disable_mfa' | 'update_profile'
type ModalKeys = 'disable_mfa' | 'update_profile'

const LazyUserForm = loadable(() => import('@components/Common/UserForm'))

const UserPopover: FC<UserPopoverProps> = ({ fullName, anchorEl, closePopover, appInitData }) => {
	const [activeModalKey, setActiveModalKey] = useState<ModalKeys>(null)

	const { replace } = useHistory()

	const organization = useOrgStore((state) => state.organization)
	const toggleSnackBar = useCommonStore((state) => state.toggleSnackBar)
	const { user, logoutUser, setShowEnableMFAModal, showEnableMFAModal, setUser } = useUserStore((state) => ({
		user: state.user,
		logoutUser: state.logoutUser,
		setShowEnableMFAModal: state.setShowEnableMFAModal,
		showEnableMFAModal: state.showEnableMFAModal,
		setUser: state.setUser,
	}))

	const queryClient = useQueryClient()

	const removeMfa = async () => {
		try {
			setActiveModalKey(null)
			await MFAService.removeAuthenticatedMfa()
			setUser({ ...user, mfa_enabled: false })
		} catch (err) {
			console.log(err)
			toggleSnackBar({
				message: 'Failed to remove MFA, please try again later',
				type: 'error',
			})
		}
	}

	const updateUserMutation = useMutation({
		mutationFn: async (formData: UpdateUserFormScheme) => {
			await UsersService.updateUser(organization.id, user.id, formData)
		},
		onMutate: () => setActiveModalKey(null),
		onError: (e) => {
			console.log(e)
			toggleSnackBar({
				message: 'An error occurred. Please try again later.',
				type: 'error',
			})
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.appInit] })
			toggleSnackBar({
				message: 'Profile updated successfully',
				type: 'success',
			})
		},
	})

	const getPopoverList = (): PopoverItem<PopoverKeys>[] => {
		const baseList = [
			{
				name: 'Edit Profile',
				value: 'update_profile',
				icon: <UserIcon />,
			},
			{
				name: 'Logout',
				value: 'logout',
				icon: <LogoutIcon />,
			},
		] as PopoverItem<PopoverKeys>[]

		const middleIndex = Math.floor(baseList.length / 2)

		if (!user.mfa_enabled) {
			baseList.splice(middleIndex, 0, {
				name: 'Enable MFA',
				value: 'enable_mfa',
				icon: <KeyIcon />,
				tooltipProps: {
					title: appInitData.is_sso_auth && 'MFA is disabled when logged in via SSO',
					placement: 'left',
				},
				disabled: appInitData.is_sso_auth,
			})
		} else {
			baseList.splice(middleIndex, 0, {
				name: 'Disable MFA',
				value: 'disable_mfa',
				icon: <DisableMFAIcon />,
			})
		}

		return baseList
	}

	const handleOptionClick = (e, item: PopoverItem<PopoverKeys>) => {
		switch (item.value) {
			case 'logout':
				logoutUser(replace, queryClient)
				break
			case 'enable_mfa':
				setShowEnableMFAModal(true)
				break
			case 'disable_mfa':
				setActiveModalKey('disable_mfa')
				break
			case 'update_profile':
				setActiveModalKey('update_profile')
				break
		}
	}

	return (
		<>
			<PopoverMenu
				classes={{ paper: 'user-popover__paper' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				darkMode
				beforeListChildren={
					<>
						<div className='user-popover__top'>
							<TooltipElement title={user.is_admin ? 'Admin' : 'Analyst'} placement='left'>
								<div className='user-popover__top--item'>
									<UserTypeIcon userType={user.is_admin ? USER_ROLE.admin : USER_ROLE.analyst} />
									<span className='user-name'>{fullName}</span>
								</div>
							</TooltipElement>

							<span className='user-email'>{user.email}</span>
						</div>

						<div className='divider' />
					</>
				}
				anchorEl={anchorEl}
				handleCloseMenu={closePopover}
				options={getPopoverList()}
				onOptionClick={handleOptionClick}
				activeOptionValues={showEnableMFAModal ? ['enable_mfa'] : [activeModalKey]}
			/>

			<EnableMFAModal />

			<Modal
				open={activeModalKey === 'disable_mfa'}
				title={{ text: 'Remove Multi-Factor Authentication', icon: 'warning' }}
				text={{
					desc: 'Are you sure you want to remove MFA?',
				}}
				buttonProps={{
					confirm: {
						onClick: removeMfa,
						text: 'Remove MFA',
						iconProps: { icon: 'warning' },
						variant: organization.settings.enforce_mfa ? 'danger' : 'secondary',
					},
					cancel: { onClick: () => setActiveModalKey(null), text: 'Cancel' },
				}}
				notes={getMfaModalNotes({
					organization,
					user,
					isCurrentUser: true,
				})}
				width={500}
				onClose={() => setActiveModalKey(null)}
				transitionDuration={200}
			/>

			<LazyUserForm
				onClose={(resetFormCb) => {
					setActiveModalKey(null)
					resetFormCb()
				}}
				scheme={updateUserFormScheme}
				modalTitle='Edit Profile'
				initialValues={user}
				confirmBtnProps={{
					icon: 'edit',
					onClick: (values) => updateUserMutation.mutateAsync(values as UpdateUserFormScheme),
					text: 'Save Changes',
				}}
				isLoading={updateUserMutation.isPending}
				open={activeModalKey === 'update_profile'}
			/>
		</>
	)
}

export default UserPopover
