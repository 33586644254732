import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { Typography } from '@components/Common/Typography/Typography'
import { ISSUE_VIEWS, type IssueViewType } from '@enums/issues'
import LogoText from '@static/common/element-logo-text.svg?react'
import PartialLogo from '@static/common/element-logo.svg?react'
import { useOrgStore } from '@stores/useOrgStore'
import { getLS } from '@utils/global-helpers'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'
import { type FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { DEFAULT_APP_ROUTES, ROUTES } from '../../../enums/routes'
import { CustomLink } from '../../Common/CustomLink/CustomLink'
import { getSideMenuTabs } from './sidemenu-consts'

const { DASHBOARD, CREDENTIALS } = ROUTES

const SideMenu: FC = () => {
	const { pathname } = useLocation()

	const allowCredsBreachRoute = useOrgStore((s) => s.allowCredsBreachRoute)
	const routeSettings = { [CREDENTIALS]: allowCredsBreachRoute } as Record<ROUTES, boolean>
	const lsIssuesView = getLS<IssueViewType>('issuesView')

	const { groupedTabs, settingsTab } = useMemo(() => {
		const tabs = getSideMenuTabs({
			issuesPath: lsIssuesView === ISSUE_VIEWS.LIST ? ROUTES.ISSUES : DEFAULT_APP_ROUTES.ISSUES,
		})

		const filteredTabs = tabs.filter((route) => routeSettings[route.path] !== false)
		const otherTabs = filteredTabs.filter((tab) => tab.id !== ROUTES.SETTINGS)

		return {
			groupedTabs: groupBy(otherTabs, 'category'),
			settingsTab: filteredTabs.find((tab) => tab.id === ROUTES.SETTINGS),
		}
	}, [lsIssuesView, routeSettings])

	return (
		<nav className='side-menu'>
			<div className='side-menu__top'>
				<CustomLink to={DASHBOARD} className='side-menu__logos'>
					<PartialLogo className='side-menu__logos--collapsed' />
					<LogoText className='side-menu__logos--expanded' />
				</CustomLink>
			</div>

			<FlexContainer direction='column' className='side-menu__content' gap='var(--s-ml)' align='flex-start'>
				{Object.entries(groupedTabs).map(([category, tabs]) => (
					<FlexContainer
						key={category}
						className='side-menu__category'
						direction='column'
						gap='var(--s-s)'
						align='flex-start'
					>
						{category !== 'undefined' && (
							<Typography variant='span' className='side-menu__category__title'>
								{category}
							</Typography>
						)}

						<FlexContainer className='side-menu__tabs' direction='column' gap='var(--s-ml)' align='flex-start'>
							{tabs.map(({ id, label, icon: Icon, path, onMouseOver }) => (
								<CustomLink
									key={id}
									className={clsx('side-menu__tabs__item', {
										selected: pathname.includes(path.split('/')[1]),
									})}
									to={path}
									onMouseOver={onMouseOver}
								>
									<Icon className='side-menu__tabs__item__icon' />
									<Typography variant='h3'>{label}</Typography>
								</CustomLink>
							))}
						</FlexContainer>
					</FlexContainer>
				))}

				{settingsTab && (
					<FlexContainer
						className='side-menu__tabs side-menu__tabs--settings'
						direction='column'
						gap='var(--s-ml)'
						align='flex-start'
					>
						<CustomLink
							key={settingsTab.id}
							className={clsx('side-menu__tabs__item', {
								selected: pathname.includes(settingsTab.path.split('/')[1]),
							})}
							to={settingsTab.path}
							onMouseOver={settingsTab.onMouseOver}
						>
							<settingsTab.icon className='side-menu__tabs__item__icon' />
							<Typography variant='h3'>{settingsTab.label}</Typography>
						</CustomLink>
					</FlexContainer>
				)}
			</FlexContainer>
		</nav>
	)
}

export default SideMenu
