import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import {
	API_JOB_STATUS,
	API_JOB_STATUS_TO_COMP,
	API_JOB_STATUS_TO_TOOLTIP,
	CSV_JOB_TYPE_TO_FILENAME,
	JOB_TO_NAME,
} from '@enums/api-jobs'
import ChevronDown from '@static/common/chevron-down.svg?react'
import FileIcon from '@static/common/file.svg?react'
import clsx from 'clsx'
import './JobsDrawer.scss'
import { IconButtonElement } from '@components/Common/IconButtonElement/IconButtonElement'
import { OverflowTip } from '@components/Common/OverflowTip/OverflowTip'
import TooltipElement from '@components/Common/TooltipElement'
import { QUERY_KEYS } from '@enums/queries'
import DragDots from '@static/common/drag-dots.svg?react'
import CloseIcon from '@static/common/times.svg?react'
import { useApiJobsStore } from '@stores/useApiJobsStore'
import { useOrgStore } from '@stores/useOrgStore'
import { useQueryClient } from '@tanstack/react-query'
import type { ApiJob, ApiJobType } from '@typings/api-job'
import type { Organization } from '@typings/organization'
import { capitilizeFirstLetters } from '@utils/global-helpers'
import { format } from 'date-fns'
import { type FC, useEffect, useMemo, useRef, useState } from 'react'
import Draggable from 'react-draggable'

type JobsDrawerProps = {
	jobs: ApiJob[]
}

const getJobName = (organization: Organization, jobType: ApiJobType): string => {
	const csvName = CSV_JOB_TYPE_TO_FILENAME[jobType]
	const fileDate = format(new Date(), 'MMddyyyy')
	return csvName ? `${capitilizeFirstLetters(organization.name)} ${csvName}_${fileDate}.csv` : JOB_TO_NAME[jobType]
}

export const JobsDrawer: FC<JobsDrawerProps> = ({ jobs }) => {
	const [expanded, setExpanded] = useState<boolean>(true)
	const [dragging, setDragging] = useState<boolean>(false)

	const nodeRef = useRef<HTMLDivElement>(null) // Needed since findDOMNode is deprecated in React 19

	const organization = useOrgStore((s) => s.organization)
	const { setHandledJobIds, setCreatedJobs } = useApiJobsStore((s) => ({
		setHandledJobIds: s.setHandledJobIds,
		setCreatedJobs: s.setCreatedJobs,
	}))
	const jobStatuses = useMemo(() => jobs.map((job) => job.status), [jobs])

	const queryClient = useQueryClient()
	const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation()
		setCreatedJobs([])
		setHandledJobIds([])
		queryClient.setQueriesData({ queryKey: [QUERY_KEYS.apiJobs], exact: false }, [])
	}

	// When a job updates or added expand the drawer
	useEffect(() => {
		if (!jobStatuses.length) return

		if (!expanded) setExpanded(true)
	}, [jobStatuses])

	const allDone = jobStatuses.every((status) => status !== API_JOB_STATUS.PENDING)

	return (
		<>
			{dragging && <div className='drag-overlay' />}

			<Draggable
				handle='.jobs-drawer__drag'
				bounds='body'
				onStart={() => setDragging(true)}
				onStop={() => setDragging(false)}
				enableUserSelectHack={false}
				nodeRef={nodeRef}
			>
				<FlexContainer ref={nodeRef} direction='column' className={clsx('jobs-drawer', { expanded })}>
					<FlexContainer
						className='jobs-drawer-top'
						onClick={() => setExpanded(!expanded)}
						justify='space-between'
						gap='var(--s-s)'
						fullWidth
					>
						<DragDots className={clsx('jobs-drawer__drag', { dragging })} onClick={(e) => e.stopPropagation()} />

						<span>{jobs.filter((j) => j.status === API_JOB_STATUS.PENDING).length} Active Tasks</span>

						<FlexContainer gap='var(--s-xxs)' align='flex-end'>
							<ChevronDown className='jobs-drawer__chevron' />

							{allDone && (
								<IconButtonElement onClick={onClose}>
									<CloseIcon className='jobs-drawer__close' />
								</IconButtonElement>
							)}
						</FlexContainer>
					</FlexContainer>

					<div className='jobs-drawer-bottom'>
						<ul className='jobs-drawer__list'>
							{jobs.map((job) => {
								const StatusIcon = API_JOB_STATUS_TO_COMP[job.status]
								return (
									<FlexContainer
										element='li'
										key={job.id}
										className='jobs-drawer__job'
										gap='var(--s-xs)'
										fullWidth
									>
										<FlexContainer gap='var(--s-xxxs)' style={{ overflow: 'hidden' }}>
											<FileIcon style={{ minWidth: '16px', minHeight: '16px' }} />
											<OverflowTip>
												<span>{getJobName(organization, job.type)}</span>
											</OverflowTip>
										</FlexContainer>

										<TooltipElement title={API_JOB_STATUS_TO_TOOLTIP[job.status]} placement='top-end'>
											<div className='jobs-drawer__job--icon'>{StatusIcon}</div>
										</TooltipElement>
									</FlexContainer>
								)
							})}
						</ul>
					</div>
				</FlexContainer>
			</Draggable>
		</>
	)
}
