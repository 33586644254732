import { DEFAULT_APP_ROUTES, ROUTES, type Routes } from '@enums/routes'
import loadable from '@loadable/component'
import SettingsIcon from '@static/common/cogwheel.svg?react'
import AssetsIcon from '@static/sidemenu/assets-icon.svg?react'
import CredentialsIcon from '@static/sidemenu/credentials-icon.svg?react'
import Dashboard from '@static/sidemenu/dashboard-icon.svg?react'
import DnsIcon from '@static/sidemenu/dns-icon.svg?react'
import IssuesIcon from '@static/sidemenu/issues-icon.svg?react'
import OrgIcon from '@static/sidemenu/organizations-icon.svg?react'
import TechnologiesIcon from '@static/sidemenu/technologies-icon.svg?react'
import VulnerabilitiesIcon from '@static/sidemenu/vulnerabilities-icon.svg?react'
import type { FC } from 'react'

const SIDEMENU_CATEGORY = {
	INVENTORY: 'Inventory',
	SECURITY: 'Alerts',
} as const

type TSideMenu = {
	id: Routes
	label: string
	icon: FC<React.SVGProps<SVGSVGElement>>
	path: string
	onMouseOver: () => void
	category?: (typeof SIDEMENU_CATEGORY)[keyof typeof SIDEMENU_CATEGORY]
}

type GetSideMenuTabs = (props: { issuesPath: string }) => TSideMenu[]

export const getSideMenuTabs: GetSideMenuTabs = (props) => [
	{
		id: ROUTES.DASHBOARD,
		label: 'Dashboard',
		icon: Dashboard,
		path: DEFAULT_APP_ROUTES.DASHBOARD,
		onMouseOver: () => loadable(() => import('@pages/Dashboard')).preload(),
	},
	{
		id: ROUTES.ORGANIZATIONS,
		label: 'Tenants',
		icon: OrgIcon,
		path: ROUTES.ORGANIZATIONS,
		onMouseOver: () => loadable(() => import('@pages/Organizations')).preload(),
	},
	{
		id: ROUTES.ISSUES,
		label: 'Issues',
		icon: IssuesIcon,
		path: props.issuesPath,
		category: SIDEMENU_CATEGORY.SECURITY,
		onMouseOver: () => loadable(() => import('@pages/Issues')).preload(),
	},
	{
		id: ROUTES.ASSETS,
		label: 'Assets',
		icon: AssetsIcon,
		path: DEFAULT_APP_ROUTES.ASSETS,
		category: SIDEMENU_CATEGORY.INVENTORY,
		onMouseOver: () =>
			loadable(() => import('@pages/Assets/Assets').then((r) => ({ default: r.Assets }))).preload(),
	},
	{
		id: ROUTES.TECHNOLOGIES,
		label: 'Tech Stack',
		icon: TechnologiesIcon,
		path: ROUTES.TECHNOLOGIES,
		category: SIDEMENU_CATEGORY.INVENTORY,
		onMouseOver: () => loadable(() => import('@pages/Technologies')).preload(),
	},
	{
		id: ROUTES.DNS,
		label: 'DNS',
		icon: DnsIcon,
		path: ROUTES.DNS,
		category: SIDEMENU_CATEGORY.INVENTORY,
		onMouseOver: () => loadable(() => import('@pages/DNS')).preload(),
	},

	{
		id: ROUTES.PATCHING,
		label: 'Patching',
		icon: VulnerabilitiesIcon,
		path: ROUTES.PATCHING,
		category: SIDEMENU_CATEGORY.SECURITY,
		onMouseOver: () => loadable(() => import('@pages/Cves')).preload(),
	},
	{
		id: ROUTES.CREDENTIALS,
		label: 'Credentials',
		icon: CredentialsIcon,
		path: ROUTES.CREDENTIALS,
		category: SIDEMENU_CATEGORY.SECURITY,
		onMouseOver: () => loadable(() => import('@pages/CredsBreach')).preload(),
	},
	{
		id: ROUTES.SETTINGS,
		label: 'Settings',
		icon: SettingsIcon,
		path: DEFAULT_APP_ROUTES.SETTINGS,
		onMouseOver: () => loadable(() => import('@pages/Settings')).preload(),
	},
]
