import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { API_JOB_TYPE } from '@enums/api-jobs'
import { useApiJobs } from '@hooks/useApiJobs'
import ReportIcon from '@static/common/report.svg?react'
import { downloadLink } from '@utils/global-helpers'
import type { FC } from 'react'
import { TopNavButton } from '../TopNav'

export const TopNavReport: FC = () => {
	const { startJob, getIsJobPending, startJobLoading } = useApiJobs()
	const isPending = getIsJobPending(API_JOB_TYPE.PDF_REPORT) || startJobLoading

	return (
		<>
			<TopNavButton
				disabled={isPending}
				className='top-nav__report'
				onClick={() =>
					startJob({
						jobType: API_JOB_TYPE.PDF_REPORT,
						onJobFinish: ({ result }) => {
							if (!result || !result.url) return

							downloadLink(result.url, { target: '_blank' })
						},
					})
				}
			>
				<FlexContainer gap='var(--s-xxxs)'>
					<ReportIcon />

					<span>Generate Report</span>
				</FlexContainer>
			</TopNavButton>
		</>
	)
}
