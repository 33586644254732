import clsx from 'clsx'
import type React from 'react'
import type { CSSProperties, ReactNode } from 'react'

type TypographyProps = {
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div'
	children: ReactNode
	className?: string
	color?: string
	fontWeight?: CSSProperties['fontWeight']
	fontSize?: CSSProperties['fontSize']
	fontFamily?: 'var(--ff-sec)' | 'var(--ff-main)'
	align?: CSSProperties['textAlign']
	pointer?: boolean
	capitalized?: boolean
} & React.HTMLAttributes<Element>

export const Typography: React.FC<TypographyProps> = ({
	variant = 'p',
	children,
	className,
	color,
	capitalized,
	fontWeight,
	fontSize,
	fontFamily,
	align,
	pointer,
	...props
}) => {
	const { style, ...restProps } = props || {}
	const ElementTag = variant as keyof JSX.IntrinsicElements

	const combinedStyles: CSSProperties = {
		color,
		fontWeight,
		fontSize,
		textAlign: align,
		fontFamily,
		cursor: pointer && 'pointer',
		...style,
	}

	return (
		<ElementTag
			className={clsx('typography', className, {
				capitalized,
			})}
			style={combinedStyles}
			{...restProps}
		>
			{children}
		</ElementTag>
	)
}
