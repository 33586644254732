import { ElementLoader } from '@components/Common/ElementLoader/ElementLoader'
import { ROUTES } from '@enums/routes'
import { useAppInit } from '@hooks/useAppInit'
import loadable from '@loadable/component'
import { useUserStore } from '@stores/useUserStore'
import type { FC } from 'react'
import { Switch } from 'react-router-dom'
import { AuthenticatedRoutes } from './AuthenticatedRoutes'
import { CustomRoute } from './CustomRoute'

const Auth = loadable(() => import('@pages/Auth/components/AuthLayout'))

export const AppRouter: FC = () => {
	const { isSuccess, data: appInitData } = useAppInit()
	const isAuthenticated = useUserStore((s) => s.isAuthenticated)

	return (
		<Switch>
			<CustomRoute
				path={ROUTES.AUTH}
				component={<Auth />}
				guardProps={{ allowEntry: !isAuthenticated, redirectTo: ROUTES.DASHBOARD }}
			/>

			{isSuccess ? <AuthenticatedRoutes appInitData={appInitData} /> : <ElementLoader />}
		</Switch>
	)
}
