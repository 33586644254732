import SnackBarElement from '@components/Common/SnackBarElement'
import { useApiJobs } from '@hooks/useApiJobs'
import { useAppInit } from '@hooks/useAppInit'
import { getLS, setLS } from '@utils/global-helpers'
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart,
	Filler,
	LineElement,
	LinearScale,
	PointElement,
	RadialLinearScale,
	Tooltip,
} from 'chart.js'
import DataLabels from 'chartjs-plugin-datalabels'
import { type FC, Fragment, type ReactNode, useState } from 'react'
import { BannerMsgStrip } from './BannerMsgStrip/BannerMsgStrip'
import { JobsDrawer } from './JobsDrawer/JobsDrawer'
import SideMenu from './SideMenu'
import { TopNav } from './TopNav/TopNav'

// Load the chartjs plugins and register them so we can use them in the app
Chart.register({
	CategoryScale,
	RadialLinearScale,
	Filler, // For the Radar chart
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Tooltip,
	ArcElement,
	DataLabels,
})

interface ILayoutProps {
	children: ReactNode
}

export const AppLayout: FC<ILayoutProps> = ({ children }) => {
	const { data: appInitQuery } = useAppInit()
	const { jobs } = useApiJobs()

	const [showBanner, setShowBanner] = useState<boolean>(() => {
		if (!appInitQuery?.banner_msg) return

		const lastAcknowledgedMessage = getLS('lastAcknowledgedMessage')
		return appInitQuery.banner_msg !== lastAcknowledgedMessage
	})

	return (
		<Fragment>
			<SideMenu />

			<div className='app-layout'>
				<BannerMsgStrip
					showBanner={showBanner}
					onClick={() => {
						setShowBanner(false)
						setLS('lastAcknowledgedMessage', appInitQuery?.banner_msg)
					}}
					message={appInitQuery?.banner_msg}
				/>

				<TopNav />

				{children}

				<SnackBarElement />
			</div>

			{jobs?.length > 0 && <JobsDrawer jobs={jobs} />}
		</Fragment>
	)
}
