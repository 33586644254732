export const ORGS = (orgId?: number) => (orgId ? `v1/orgs/${orgId}` : 'v1/orgs')

export const BASE_API_ROUTES = {
	INTEGRATIONS: (orgId?: number) => `${ORGS(orgId)}/integrations`,
	USERS: (orgId?: number) => (orgId ? `v1/users/${orgId}` : 'v1/users'),
	DNS: (orgId: number) => `${ORGS(orgId)}/dns`,
	CREDS: (orgId: number) => `${ORGS(orgId)}/credentials`,
	ISSUES: (orgId: number) => `${ORGS(orgId)}/issues`,
	ISSUES_CVSS: (orgId: number) => `${ORGS(orgId)}/issues/cvss`,
	ISSUES_ASSIGNMENTS: (orgId: number) => `${ORGS(orgId)}/issues/assignments`,
	NETBLOCKS: (orgId: number) => `${ORGS(orgId)}/assets/ips/netblocks`,
	ASSETS: (orgId: number) => `${ORGS(orgId)}/assets`,
	PATCHING: (orgId: number) => `${ORGS(orgId)}/patching`,
	TECHS: (orgId: number) => `${ORGS(orgId)}/technologies`,
	SSO: (orgId: number) => `${ORGS(orgId)}/sso`,
	AUTH: (orgId?: number) => (orgId ? `v1/auth/${orgId}` : 'v1/auth'),
	MFA: 'v1/auth/mfa',
	MFA_PUBLIC: 'v1/auth/mfa/public',
	ASSET_CONNECTIONS: (orgId: number) => `${ASSETS(orgId)}/connections`,
	SMART_SEARCH: (orgId?: number) => (orgId ? `${ORGS(orgId)}/smart-search` : 'v1/smart-search'),
	TAGS_PORT_MAPPING: (orgId: number) => `${ASSETS(orgId)}/tags/port/mapping`,
} as const

const {
	INTEGRATIONS,
	USERS,
	DNS,
	CREDS,
	ISSUES,
	ASSETS,
	PATCHING,
	NETBLOCKS,
	AUTH,
	TECHS,
	ASSET_CONNECTIONS,
	MFA,
	MFA_PUBLIC,
	SMART_SEARCH,
	ISSUES_CVSS,
	ISSUES_ASSIGNMENTS,
	TAGS_PORT_MAPPING,
} = BASE_API_ROUTES

// Fast API Routes

export const USERS_ROUTES = {
	DEACTIVATE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/disable`,
	ACTIVATE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/enable`,
	UPDATE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}`,
	UPDATE_ROLE: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/role`,
	RESET_PWD: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/reset-password`,
	UNENROLL_MFA: (orgId: number, userId: number) => `${USERS(orgId)}/${userId}/mfa/unenroll`,
} as const

export const INTEGRATIONS_ROUTES = {
	BASE: (orgId: number, integrationId?: number) =>
		integrationId ? `${INTEGRATIONS(orgId)}/${integrationId}` : INTEGRATIONS(orgId),
} as const

export const INIT_ROUTES = {
	BASE: 'v1/init',
} as const

export const AUTH_ROUTES = {
	LOGIN: `${AUTH()}/login`,
	PRELOGIN: `${AUTH()}/prelogin`,
	LOGOUT: `${AUTH()}/logout`,
	REGISTER: `${AUTH()}/register`,
	RESET_PWD: `${AUTH()}/reset-password`,

	CHECK_INVITATION_TOKEN: `${AUTH()}/check-invitation`,
	CHECK_RESET_PWD_TOKEN: `${AUTH()}/check-reset-pwd`,
	CHECK_SESSION: `${AUTH()}/session`,
	INVITE: (orgId: number) => `${AUTH(orgId)}/invite`,
	REINVITE: (orgId: number, userId: number) => `${AUTH(orgId)}/invite/${userId}`,
	REQ_PWD_RESET: (orgId: number, userId: number) => `${AUTH(orgId)}/request-password-reset/${userId}`,
	REQ_MFA_RESET: (orgId: number, userId: number) => `${AUTH(orgId)}/request-mfa-reset/${userId}`,
} as const

export const MFA_ROUTES = {
	ENABLE_MFA: `${MFA}/enable`,
	VERIFY_MFA: `${MFA}/verify`,
	ENABLE_PUBLIC_MFA: `${MFA_PUBLIC}/enable`,
}

export const CREDS_ROUTES = {
	BASE: (orgId: number) => `${CREDS(orgId)}`,
	COUNT: (orgId: number) => `${CREDS(orgId)}/count`,
	DETAILS: (orgId: number, credentialId: number) => `${CREDS(orgId)}/${credentialId}`,
	FILTER_VALUES: (orgId: number) => `${CREDS(orgId)}/filters`,
	CSV: (orgId: number, credId: number) => `${CREDS(orgId)}/${credId}/csv`,
	TAGS: (orgId: number) => `${CREDS(orgId)}/tags`,
	SMART_SEARCH_AUTOCOMPLETE: (orgId: number) => `${CREDS(orgId)}/autocomplete`,
	SMART_SEARCH_QUERIES: (orgId: number) => `${CREDS(orgId)}/smart-search-queries`,
}

export const DNS_ROUTES = {
	CSV: (orgId: number, dnsId: number) => `${DNS(orgId)}/${dnsId}/csv`,
} as const

export const PATCHING_ROUTES = {
	CVES: (orgId: number) => `${PATCHING(orgId)}/cves`,
	CVES_ID: (orgId: number, cveId: string) => `${PATCHING(orgId)}/cves/${cveId}`,
	HEADER: (orgId: number) => `${PATCHING(orgId)}/header`,
	CVES_FILTERS: (orgId: number) => `${PATCHING(orgId)}/cves/filters`,
	CVES_CSV: (orgId: number, cveId: string) => `${PATCHING(orgId)}/cves/${cveId}/csv`,
	PATCHING_ID: (orgId: number, patchingId: number) => `${PATCHING(orgId)}/${patchingId}`,
	PATCHING_HOSTS: (orgId: number, cveId: string) => `${PATCHING(orgId)}/hosts/${cveId}`,
} as const

export const NETBLOCKS_ROUTES = {
	UPDATE: (orgId: number, netblockId: number) => `${ORGS(orgId)}/assets/ips/netblocks/${netblockId}`,
	ADD_IPS: (orgId: number) => `${ASSETS(orgId)}/ips/create`,
	CSV: (orgId: number, netblockId: number) => `${NETBLOCKS(orgId)}/${netblockId}/csv`,
} as const

export const ORGANIZATION_ROUTES = {
	API: (orgId: number) => `${ORGS(orgId)}/api_keys`,
	API_GENERATE: (orgId: number) => `${ORGS(orgId)}/api_keys/generate`,
	API_KEY_ID: (orgId: number, apiKeyId: number) => `${ORGS(orgId)}/api_keys/${apiKeyId}`,
	ORGANIZATIONS_SCREEN: (orgId: number) => `${ORGS(orgId)}`,
	ORGANIZATIONS_HEADER: (orgId: number) => `${ORGS(orgId)}/header`,
	REACTIVATE_ORG: (orgId: number) => `${ORGS(orgId)}/activate`,
	DEACTIVATE_ORG: (orgId: number) => `${ORGS(orgId)}/deactivate`,
	UPDATE_ORG: (orgId: number) => `${ORGS(orgId)}`,
	UPDATE_ORG_SETTINGS: (orgId: number) => `${ORGS(orgId)}/settings`,
	CONFIG_OPTIONS: 'v1/config-options',
} as const

export const API_JOBS_ROUTES = {
	BASE: (orgId: number) => `${ORGS(orgId)}/api-jobs`,
} as const

export const ISSUES_ROUTES = {
	STATUS: (orgId: number) => `${ISSUES(orgId)}/status`,
	FILTER_VALUE: (orgId: number) => `${ISSUES(orgId)}/filters`,
	DETAILS: (issueId: number, orgId: number) => `${ISSUES(orgId)}/${issueId}`,
	GROUPS: (orgId: number) => `${ISSUES(orgId)}/groups`,
	HISTORY: (issueId: number, orgId: number) => `${ISSUES(orgId)}/${issueId}/history`,
	EVIDENCE: (issueId: number, orgId: number) => `${ISSUES(orgId)}/${issueId}/evidence`,

	RETEST_ID: (orgId: number, issueId: number) => `${ISSUES(orgId)}/${issueId}/retest`,
	RETEST: (orgId: number) => `${ISSUES(orgId)}/retest`,

	TAGS: (orgId: number) => `${ISSUES(orgId)}/tags`,

	CSV: (orgId: number, issueId: number) => `${ISSUES(orgId)}/${issueId}/csv`,

	ASSIGNMENTS_ID: (orgId: number, issueId: number, userId: number) =>
		`${ISSUES_ASSIGNMENTS(orgId)}/${issueId}/${userId}`,
} as const

export const ISSUES_CVSS_ROUTES = {
	BASE: (orgId: number, issueId: number) => `${ISSUES_CVSS(orgId)}/${issueId}`,
	CUSTOM_VECTOR: (orgId: number, issueDetailId: number) => `${ISSUES_CVSS(orgId)}/vector/${issueDetailId}`,
	RESTORE_VECTOR: (orgId: number, issueId: number) => `${ISSUES_CVSS(orgId)}/vector/${issueId}/restore`,
} as const

export const COMMENTS_ROUTES = {
	ISSUES: (orgId: number, issueId?: number) =>
		issueId ? `${ISSUES(orgId)}/comments/${issueId}` : `${ISSUES(orgId)}/comments`,

	ASSETS: (orgId: number, assetId?: number) =>
		assetId ? `${ASSETS(orgId)}/comments/${assetId}` : `${ASSETS(orgId)}/comments`,
} as const

export const ASSETS_ROUTES = {
	ASSET_COUNT_BY_TYPE: (orgId: number, assetType: string) => `${ASSETS(orgId)}/${assetType}/count`,
	ADD_ASSETS: (orgId: number) => `${ASSETS(orgId)}/create`,
	ASSETS_BY_TYPE: (orgId: number, assetType: string) => `${ASSETS(orgId)}/${assetType}`,
	TAGS: (orgId: number) => `${ASSETS(orgId)}/tags`,

	FILTER_VALUES: (orgId: number) => `${ASSETS(orgId)}/filters/initial`,

	ALERTS: (orgId: number) => `${ASSETS(orgId)}/alerts`,
	DISCOVERY_GRAPH: (orgId: number) => `${ASSET_CONNECTIONS(orgId)}/graph`,
	PORTS: (orgId: number) => `${ASSETS(orgId)}/ports`,
	HTTP: (orgId: number) => `${ASSETS(orgId)}/http`,
	HISTORY: (orgId: number) => `${ASSETS(orgId)}/history`,

	ASSETS_DETAILS: (data: { orgId: number; assetType: string; assetId: number }) => {
		const { orgId, assetType, assetId } = data
		return `${ASSETS(orgId)}/${assetType}/${assetId}`
	},

	DETAILS_CSV: (data: { orgId: number; assetType: string; assetId: number }) => {
		const { orgId, assetType, assetId } = data
		return `${ASSETS(orgId)}/${assetType}/${assetId}/csv`
	},

	ALERTS_CSV: (orgId: number) => `${ASSETS(orgId)}/csv/alerts`,

	ACTIVITY_CSV: (orgId: number) => `${ASSETS(orgId)}/csv/activity`,

	SMART_SEARCH_QUERIES: (orgId: number) => `${ASSETS(orgId)}/smart-search-queries`,
	SMART_SEARCH_QUERIES_BY_TYPE: (orgId: number, assetType: string) =>
		`${ASSETS(orgId)}/${assetType}/smart-search-queries`,
	SMART_SEARCH_AUTOCOMPLETE: (orgId: number) => `${ASSETS(orgId)}/autocomplete`,
	SMART_SEARCH_AUTOCOMPLETE_BY_TYPE: (orgId: number, assetType: string) =>
		`${ASSETS(orgId)}/${assetType}/autocomplete`,

	DELETE_TAGS_PORT_MAPPING: (orgId: number, tagId: number) => `${TAGS_PORT_MAPPING(orgId)}/${tagId}`,
} as const

export const DASHBOARD = {
	GET_DASHBOARD: (orgId: number) => `${ORGS(orgId)}/dashboard`,
	GET_SCORE_OVERTIME: (orgId: number) => `${ORGS(orgId)}/dashboard/score-overtime`,
} as const

export const TECH_ROUTES = {
	FILTER_VALUES: (orgId: number) => `${TECHS(orgId)}/filters`,
	LIST: (orgId: number) => `${TECHS(orgId)}/list`,
	GROUP: (orgId: number) => `${TECHS(orgId)}/groups`,
	CSV: (orgId: number) => `${TECHS(orgId)}/csv`,
} as const

export const SSO_ROUTES = {
	DOMAIN: (orgId: number) => `${BASE_API_ROUTES.SSO(orgId)}/domain`,
	DOMAIN_ID: (orgId: number, domainId: number) => `${BASE_API_ROUTES.SSO(orgId)}/domain/${domainId}`,
	SETTINGS: (orgId: number) => `${BASE_API_ROUTES.SSO(orgId)}/settings`,
	SETTINGS_ID: (orgId: number, settingsId: number) => `${BASE_API_ROUTES.SSO(orgId)}/settings/${settingsId}`,
} as const

export const SMART_SEARCH_ROUTES = {
	UPDATE_SMART_QUERY_ITEM: (orgId: number, queryId: number) => `${SMART_SEARCH(orgId)}/${queryId}`,
	CONVERT_TO_SMART_QUERY: (orgId: number) => `${SMART_SEARCH(orgId)}/convert`,
} as const
