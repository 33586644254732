import { IconButton, type IconButtonProps } from '@mui/material'
import clsx from 'clsx'
import type React from 'react'
import { forwardRef } from 'react'
import { CustomLink } from '../CustomLink/CustomLink'
import './IconButtonElement.scss'

type IIconButtonIconButtonElementProps = {
	children: React.ReactNode
	size?: 'small' | 'medium' | 'large'
	disabled?: boolean
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
	customClass?: string
	linkPath?: string
} & IconButtonProps

export const IconButtonElement = forwardRef<any, IIconButtonIconButtonElementProps>(
	({ children, size = 'small', disabled, customClass, onClick, linkPath, ...rest }, ref) => {
		return (
			<>
				{linkPath ? (
					<CustomLink
						to={linkPath}
						className={clsx('icon-button-element', { [customClass]: customClass, disabled })}
						ref={ref}
					>
						<IconButton disabled={disabled} size={size} sx={{ padding: '0px', margin: '0px' }} {...rest}>
							{children}
						</IconButton>
					</CustomLink>
				) : (
					<IconButton
						ref={ref}
						classes={{ root: clsx('icon-button-element', { [customClass]: customClass, disabled }) }}
						onClick={onClick}
						disabled={disabled}
						size={size}
						sx={{ padding: '0px', margin: '0px' }}
						{...rest}
					>
						{children}
					</IconButton>
				)}
			</>
		)
	},
)
