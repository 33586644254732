import { ButtonElement } from '@components/Common/ButtonElement/ButtonElement'
import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import Loader from '@components/Common/Loader'
import StyledTextField from '@components/Common/StyledTextField'
import { Typography } from '@components/Common/Typography/Typography'
import { MFAService } from '@services/apis/mfa'
import { useCommonStore } from '@stores/useCommonStore'
import { useUserStore } from '@stores/useUserStore'
import { extractUIError } from '@utils/global-helpers'
import { type FC, useEffect, useState } from 'react'
import type { MFAProps } from '../MFA'
import { VerifyMFAInputs } from '../VerifyMFAInputs/VerifyMFAInputs'
import './VerifyMFA.scss'

type VerifyMFAProps = {
	mfaToken: string
	setMfaProps: (mfaProps: MFAProps) => void
}

export const VerifyMFA: FC<VerifyMFAProps> = ({ mfaToken, setMfaProps }) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [apiErrorMsg, setApiErrorMsg] = useState<string>('')
	const [inputValues, setInputValues] = useState<string[]>(Array.from({ length: 6 }, () => ''))
	const [useBackupCode, setUseBackupCode] = useState<boolean>(false)

	const setIsAuthenticated = useUserStore((state) => state.setIsAuthenticated)
	const toggleSnackBar = useCommonStore((s) => s.toggleSnackBar)

	const verifyMFA = async () => {
		setLoading(true)
		try {
			await MFAService.verifyMFA({
				mfa_token: mfaToken,
				value: inputValues.join(''),
				use_backup_code: useBackupCode,
			})
			setIsAuthenticated(true)
		} catch (error) {
			console.error(error)
			const msg = extractUIError(error)
			if (msg) return setApiErrorMsg(msg)
			toggleSnackBar({
				message: 'An error occurred while verifying MFA, please re-login and try again',
				type: 'error',
			})
			setMfaProps(null)
		} finally {
			setLoading(false)
		}
	}

	const onMfaTypeChange = () => {
		setUseBackupCode((prev) => !prev)
		setInputValues(Array.from({ length: 6 }, () => ''))
		setApiErrorMsg('')
	}

	// Auto verify MFA if all inputs are filled
	useEffect(() => {
		if (!useBackupCode && inputValues.every((v) => v)) {
			verifyMFA()
		}
	}, [inputValues])

	return (
		<FlexContainer fullWidth direction='column' className='verify-mfa'>
			<Loader loading={loading} />

			<FlexContainer
				direction='column'
				gap='var(--s-s)'
				className='verify-mfa__form'
				element={'form'}
				onSubmit={(e) => {
					e.preventDefault()
					verifyMFA()
				}}
			>
				{useBackupCode ? (
					<StyledTextField
						fullWidth
						autoFocus
						value={inputValues.join('')}
						onChange={(e) => setInputValues(Array.from(e.target.value))}
						placeholder='Enter Backup Code'
					/>
				) : (
					<VerifyMFAInputs setInputValues={setInputValues} inputValues={inputValues} />
				)}

				<Typography
					fontSize='var(--text-size-2)'
					fontWeight={500}
					color='var(--tertiary-1)'
					role='button'
					pointer
					onClick={onMfaTypeChange}
				>
					{useBackupCode ? 'Use Authenticator Code' : 'Use Backup Code'}
				</Typography>

				<FlexContainer direction='column' gap='var(--s-xxs)' fullWidth>
					<ButtonElement
						variant='secondary'
						type='submit'
						fullWidth
						disabled={inputValues.some((v) => !v) || loading}
						scaleOnTap
						iconProps={{ icon: 'checkmark' }}
					>
						Verify
					</ButtonElement>

					<ButtonElement onClick={() => setMfaProps(null)} fullWidth scaleOnTap>
						Back to Login
					</ButtonElement>
				</FlexContainer>

				{apiErrorMsg && (
					<Typography
						style={{ whiteSpace: 'pre' }}
						fontWeight={500}
						fontSize={'var(--text-size-3)'}
						color='var(--red-3)'
					>{`* ${apiErrorMsg}`}</Typography>
				)}
			</FlexContainer>
		</FlexContainer>
	)
}
