export const ROUTES = {
	AUTH: '/auth',
	LOGIN: '/auth/login',
	REGISTER: '/auth/register',
	RESET_PASSWORD: '/auth/reset-password',

	DASHBOARD: '/dashboard',

	SETTINGS: '/settings',
	ADD_ORG: '/tenant',

	// Maintenance
	MAINTENANCE: '/maintenance',

	// Tables
	ORGANIZATIONS: '/tenants',
	ISSUES: '/issues',
	ASSETS: '/assets',
	DNS: '/dns',
	TECHNOLOGIES: '/technologies',
	PATCHING: '/patching',
	CREDENTIALS: '/credentials',
} as const

export type Routes = (typeof ROUTES)[keyof typeof ROUTES]

export const DEFAULT_APP_ROUTES = {
	ASSETS: `${ROUTES.ASSETS}/all`,
	ISSUES: `${ROUTES.ISSUES}/table`,
	DASHBOARD: ROUTES.DASHBOARD,
	SETTINGS: `${ROUTES.SETTINGS}/environment`,
	ADD_ORG: `${ROUTES.ADD_ORG}/environment`,
}
