import { Tooltip, type TooltipProps } from '@mui/material'
import clsx from 'clsx'
import type { FC, ReactElement } from 'react'

export type ITooltipElement = {
	children: ReactElement
	customTooltipClasses?: string
	darkMode?: boolean
} & TooltipProps

export type OmittedTooltipProps = Omit<ITooltipElement, 'children'>

const TooltipElement: FC<ITooltipElement> = ({
	placement = 'top',
	customTooltipClasses,
	enterDelay = 50,
	children,
	darkMode,
	arrow = true,
	...restProps
}) => {
	return (
		<Tooltip
			enterDelay={enterDelay}
			enterNextDelay={enterDelay}
			PopperProps={{
				onClick: (e) => {
					e.stopPropagation() // Prevent clicks going through the tooltip
				},
			}}
			TransitionProps={{ timeout: 125 }}
			placement={placement}
			arrow={arrow}
			className='tooltip-element'
			classes={{
				tooltip: clsx('tooltip-root', customTooltipClasses, { 'tooltip-root--dark': darkMode }),
				popper: 'tooltip-popper',
				arrow: 'tooltip-arrow',
				tooltipPlacementTop: 'tooltip-top',
			}}
			{...restProps}
		>
			{children}
		</Tooltip>
	)
}

export default TooltipElement
