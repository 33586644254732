import clsx from 'clsx'
import type React from 'react'
import { type FC, type ReactElement, useEffect, useRef, useState } from 'react'
import TooltipElement from '../TooltipElement'
import type { ITooltipElement } from '../TooltipElement/TooltipElement'
import './OverflowTip.scss'
interface IOverflowTipProps {
	children: ReactElement | string
	style?: React.CSSProperties
	className?: string
	tooltipProps?: Omit<ITooltipElement, 'title' | 'children'>
}

export const OverflowTip: FC<IOverflowTipProps> = ({ children, style, tooltipProps, className }) => {
	const [isOverflowed, setIsOverflow] = useState(false)
	const textElementRef = useRef(null)

	useEffect(() => {
		const { current } = textElementRef || {}
		if (!current) return

		const handleOverflow = () => setIsOverflow(current.scrollWidth > current.clientWidth)

		handleOverflow()
	}, [])

	const text = typeof children === 'string' ? children : children?.props?.children

	return (
		<TooltipElement title={text ?? ''} disableHoverListener={!isOverflowed} enterDelay={250} {...tooltipProps}>
			<div ref={textElementRef} className={clsx('overflow-tip', className)} style={style}>
				{children}
			</div>
		</TooltipElement>
	)
}
