import type { CSSProperties, FC } from 'react'

interface LoaderProps {
	loading: boolean
	size?: number
}

const Loader: FC<LoaderProps> = ({ loading, size = 11.5 }) => {
	return (
		<>
			{loading && (
				<div className='loader' style={{ '--size': `${size}px` } as CSSProperties}>
					<div />
					<div />
					<div />
					<div />
				</div>
			)}
		</>
	)
}

export default Loader
