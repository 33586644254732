import QuestionMarkIcon from '@static/common/question-mark.svg'
import { isNill } from '@utils/global-helpers'
import clsx from 'clsx'
import type React from 'react'
import type { FC, SyntheticEvent } from 'react'
import CircularLoader from '../CircularLoader'
interface IUiImageProps {
	src: string
	alt: string
	onLoad?: () => void
	onError?: (e: SyntheticEvent<HTMLImageElement>) => void
	customClass?: string
	imageLoaded?: boolean
	load?: 'lazy' | 'eager'
	imgStyle?: React.CSSProperties
	containerStyle?: React.CSSProperties
}

export const UiImage: FC<IUiImageProps> = ({
	src,
	onLoad,
	onError,
	customClass,
	imageLoaded,
	imgStyle,
	containerStyle,
	load = 'lazy',
	alt,
}) => {
	const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>, imgPath: string) => {
		e.currentTarget.onerror = null
		e.currentTarget.src = imgPath
	}

	const styles = onLoad ? { opacity: imageLoaded ? 1 : 0, transition: 'opacity 100ms ease' } : {}

	return (
		<div className={clsx('ui-image', customClass)} style={containerStyle}>
			<img
				alt={alt}
				src={src}
				style={{ ...styles, ...imgStyle }}
				loading={load}
				onLoad={onLoad && onLoad}
				onError={(e) => (onError ? onError : handleImageError(e, QuestionMarkIcon))}
			/>

			{!imageLoaded && !isNill(onLoad) && <CircularLoader loading />}
		</div>
	)
}
