import Modal from '@components/Common/Modal'
import { MFA, type MFAProps, MFAStep } from '@pages/Auth/components/MFA/MFA'
import KeyIcon from '@static/sidemenu/credentials-icon.svg?react'
import { useCommonStore } from '@stores/useCommonStore'
import { useUserStore } from '@stores/useUserStore'
import { type FC, useState } from 'react'

export const EnableMFAModal: FC = () => {
	const [mfaProps, setMfaProps] = useState<MFAProps>({ step: MFAStep.ENABLE, token: null })

	const toggleSnackBar = useCommonStore((state) => state.toggleSnackBar)
	const { user, setShowEnableMFAModal, showEnableMFAModal, setUser } = useUserStore((state) => ({
		user: state.user,
		setShowEnableMFAModal: state.setShowEnableMFAModal,
		showEnableMFAModal: state.showEnableMFAModal,
		setUser: state.setUser,
	}))

	const onBackupAcknowledge = () => {
		setShowEnableMFAModal(false)
		toggleSnackBar({
			message: 'Multi-Factor Authentication enabled successfully',
			type: 'success',
		})
		setUser({ ...user, mfa_enabled: true })

		// Reset back to the first step, so that the next time the modal is opened, it will start from the first step
		setTimeout(() => {
			setMfaProps({ step: MFAStep.ENABLE, token: null })
		}, 400)
	}

	const isAllowedToClose = mfaProps.step !== MFAStep.BACKUP
	return (
		<Modal
			open={showEnableMFAModal}
			title={{
				text: 'Enable Multi-Factor Authentication',
				icon: KeyIcon,
			}}
			onClose={isAllowedToClose ? () => setShowEnableMFAModal(false) : null}
			customClass='mfa-modal'
		>
			<MFA setMfaProps={setMfaProps} mfaProps={mfaProps} onBackupAcknowledge={onBackupAcknowledge} />
		</Modal>
	)
}
