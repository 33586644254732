import { axiosInstance } from '@services/axios'
import { returnData } from '@services/http-helpers'
import type {
	LoginToMFAResp,
	PreLoginResp,
	RegisterPayload,
	ResetPwdPayload,
	TokenChecksResp,
} from '@typings/auth'
import type { MessageResponse } from '@typings/generics'
import type { AddUserFormScheme } from '@yup/add-user'
import type { LoginSchemeType } from '@yup/login'
import { AUTH_ROUTES } from '../services-consts'

class AuthServiceClass {
	preLogin = async (email: string) => {
		return axiosInstance.post<PreLoginResp>(AUTH_ROUTES.PRELOGIN, { email }).then(returnData)
	}

	login = async (payload: LoginSchemeType): Promise<MessageResponse | LoginToMFAResp> => {
		return axiosInstance.post(AUTH_ROUTES.LOGIN, payload).then(returnData)
	}

	register = async (payload: RegisterPayload): Promise<MessageResponse> => {
		return axiosInstance.post(AUTH_ROUTES.REGISTER, payload).then(returnData)
	}

	resetPwd = async (payload: ResetPwdPayload): Promise<MessageResponse> => {
		return axiosInstance.post(AUTH_ROUTES.RESET_PWD, payload).then(returnData)
	}

	logout = async (): Promise<MessageResponse> => {
		return axiosInstance.post(AUTH_ROUTES.LOGOUT).then(returnData)
	}

	checkSession = async (): Promise<MessageResponse> => {
		return axiosInstance.get(AUTH_ROUTES.CHECK_SESSION).then(returnData)
	}

	checkInvitationToken = async (token: string) => {
		return axiosInstance
			.get<TokenChecksResp>(AUTH_ROUTES.CHECK_INVITATION_TOKEN, { params: { token } })
			.then(returnData)
	}

	checkResetPwdToken = async (token: string) => {
		return axiosInstance
			.get<TokenChecksResp>(AUTH_ROUTES.CHECK_RESET_PWD_TOKEN, { params: { token } })
			.then(returnData)
	}

	inviteUser = async (orgId: number, newUser: AddUserFormScheme) => {
		return axiosInstance.post<MessageResponse>(AUTH_ROUTES.INVITE(orgId), newUser).then(returnData)
	}

	reInviteUser = async (orgId: number, userId: number) => {
		return axiosInstance.post<MessageResponse>(AUTH_ROUTES.REINVITE(orgId, userId)).then(returnData)
	}

	requestPasswordReset = async (orgId: number, userId: number) => {
		return axiosInstance.post<MessageResponse>(AUTH_ROUTES.REQ_PWD_RESET(orgId, userId)).then(returnData)
	}

	requestMFAReset = async (orgId: number, userId: number) => {
		return axiosInstance.post<MessageResponse>(AUTH_ROUTES.REQ_MFA_RESET(orgId, userId)).then(returnData)
	}
}

export const AuthService = new AuthServiceClass()
