import { FlexContainer } from '@components/Common/FlexContainer/FlexContainer'
import { ROUTES } from '@enums/routes'
import { CustomRoute } from '@routing/CustomRoute'
import ConstructionIcon from '@static/common/construction.svg?react'
import ElementLogo from '@static/common/element-logo-full.svg?react'
import type { FC } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import './Maintenance.scss'

const Maintenance: FC = () => {
	return (
		<FlexContainer className='maintenance' align='center' justify='center' fullWidth>
			<FlexContainer
				direction='column'
				align='center'
				justify='center'
				className='maintenance__container'
				fullWidth
			>
				<FlexContainer className='maintenance__content' align='flex-start' justify='space-between' fullWidth>
					<FlexContainer className='maintenance__msg' direction='column' align='flex-start'>
						<ElementLogo className='maintenance__logo' />

						<h1 className='maintenance__msg--title'>Sorry!</h1>

						<span className='maintenance__msg--text'>Taking a quick break for some updates.</span>
						<span className='maintenance__msg--text'>We will be back up, better and stronger, soon!</span>
					</FlexContainer>

					<ConstructionIcon className='maintenance__icon' />
				</FlexContainer>
			</FlexContainer>
		</FlexContainer>
	)
}

export const MaintenanceApp = () => (
	<Switch>
		<CustomRoute exact path={ROUTES.MAINTENANCE} component={<Maintenance />} />
		<CustomRoute component={<Redirect to={ROUTES.MAINTENANCE} />} path='*' />
	</Switch>
)
