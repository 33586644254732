import { MAINTENANCE_CODE } from '@enums/generics'
import { QUERY_KEYS } from '@enums/queries'
import { OrganizationService } from '@services/apis/organization'
import { useCommonStore } from '@stores/useCommonStore'
import { useOrgStore } from '@stores/useOrgStore'
import { useUserStore } from '@stores/useUserStore'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { extractUIError } from '@utils/global-helpers'
import { useHistory } from 'react-router-dom'

const INTERCEPTOR_CODES = [MAINTENANCE_CODE, 401]

export const useAppInit = () => {
	const { replace } = useHistory()
	const { host } = window.location

	const { logoutUser, setUser, isAuthenticated } = useUserStore((s) => ({
		logoutUser: s.logoutUser,
		setUser: s.setUser,
		isAuthenticated: s.isAuthenticated,
	}))

	const setOrganization = useOrgStore((s) => s.setOrganization)

	const toggleSnackBar = useCommonStore((s) => s.toggleSnackBar)
	const urlWorkSpace = host.split('.')?.[0]?.toLowerCase()
	const queryClient = useQueryClient()

	return useQuery({
		queryKey: [QUERY_KEYS.appInit],
		queryFn: async ({ signal }) => {
			const data = await OrganizationService.onAppInit(signal)

			const workspaceOrg = data.organizations.find(({ workspace }) => workspace.toLowerCase() === urlWorkSpace)

			setUser(data.user)
			if (workspaceOrg) setOrganization(workspaceOrg) // Will show the app, else will show the workspace selection Modal

			return data
		},
		select: (data) => {
			const sortedOrgs = [...data.organizations].sort((a, b) => {
				const dateA = new Date(a.created_on).getTime()
				const dateB = new Date(b.created_on).getTime()
				return dateA - dateB
			})

			return { ...data, organizations: sortedOrgs }
		},

		meta: {
			errorCallback: (err) => {
				const code = err?.response?.status
				const isDev = import.meta.env.MODE === 'development'
				if (INTERCEPTOR_CODES.includes(code) || isDev) return

				// This logic will only happen for errors other than 401 and 503
				// 401 and 503 errors are handled in the interceptor

				const msg = extractUIError(err)
				logoutUser(replace, queryClient)
				toggleSnackBar({
					message: msg ?? 'An error occured, please try again later',
					type: 'error',
				})
			},
		},

		retry: (retryCount, error) => {
			if (INTERCEPTOR_CODES.includes(error?.response?.status)) return
			return retryCount < 1
		},

		refetchOnMount: false, // So it won't refetch whenever we call the hook
		gcTime: Number.POSITIVE_INFINITY, // Always keep the data in context
		refetchInterval: 1000 * 60 * 5, // 5 minutes
		refetchIntervalInBackground: true,
		enabled: isAuthenticated,
	})
}
