import { type CSSProperties, forwardRef, useEffect, useRef, useState } from 'react'
import './UserAvatar.scss'

type UserAvatarProps = {
	name: string
	color?: string
	circleSize?: number // Circle size
} & React.HTMLAttributes<HTMLDivElement>

const getInitials = (name: string) => {
	return name
		.split(' ')
		.map((el) => el[0])
		.join('')
		.toUpperCase()
		.slice(0, 2)
}

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(
	({ name, color, circleSize = 26, ...props }, ref) => {
		const [fontSize, setFontSize] = useState<number>(circleSize * 0.5)
		const textRef = useRef<HTMLSpanElement>(null)

		const initials = getInitials(name)

		useEffect(() => {
			if (!textRef.current) return

			const adjustFontSize = () => {
				const textWidth = textRef.current?.offsetWidth || 0
				if (textWidth > circleSize * 0.85) {
					setFontSize((prev) => prev * 0.85) // Reduce font size incrementally
				}
			}

			adjustFontSize()
		}, [circleSize, initials])

		return (
			<div
				ref={ref}
				{...props}
				className='user-avatar'
				style={
					{
						'--clr': color,
						'--size': `${circleSize}px`,
						'--fz': `${fontSize}px`,
					} as CSSProperties
				}
			>
				<span ref={textRef}>{initials}</span>
			</div>
		)
	},
)
