import { Dialog } from '@mui/material'
import PenIcon from '@static/common/pen.svg?react'
import Plus from '@static/common/rectangle-plus.svg?react'
import RoundCheckmark from '@static/common/round-checkmark.svg?react'
import TrashIcon from '@static/common/trash.svg?react'
import WarningIcon from '@static/issues/open-issue.svg?react'
import { isObjEmpty } from '@utils/global-helpers'
import clsx from 'clsx'
import type React from 'react'
import type { CSSProperties, ComponentProps, FC, SyntheticEvent } from 'react'
import CloseIcon from '../../../static/common/times.svg?react'
import { ButtonElement } from '../ButtonElement/ButtonElement'
import { IconButtonElement } from '../IconButtonElement/IconButtonElement'

export type PredefinedModalIcons = 'plus' | 'checkmark' | 'trash' | 'warning' | 'edit'
type ModalButton = Omit<ComponentProps<typeof ButtonElement>, 'children'> & { text: string }
export interface ModalButtonProps {
	confirm?: ModalButton
	cancel?: ModalButton
}

type ModalTitle = {
	text: string
	icon?: PredefinedModalIcons | FC<React.SVGProps<SVGSVGElement>>
	iconColor?: string
}
export interface ModalProps {
	open: boolean
	title: ModalTitle

	buttonProps?: ModalButtonProps
	onClose?: (e: SyntheticEvent) => void
	text?: { desc: string; bold?: string | string[] }
	notes?: string[]
	transitionDuration?: number
	width?: number
	children?: React.ReactNode
	style?: CSSProperties
	customClass?: string
}

export const Modal: FC<ModalProps> = ({
	text,
	title,
	open,
	onClose,
	transitionDuration,
	buttonProps = {},
	width = 600,
	children,
	notes = [],
	customClass,
	style,
}) => {
	const { confirm: cofirmBtnProps, cancel: cancelBtnProps } = buttonProps

	// Format to HTML, set <b> tags around texts that are in the bold array/string
	const formatTextToHtml = () => {
		const { bold, desc } = text || {}

		if (!bold) return desc

		if (Array.isArray(bold)) {
			let newText = desc ?? ''
			bold.forEach((b) => (newText = newText.replaceAll(b, `<b>${b}</b>`)))
			return newText
		}
		if (!desc.includes(bold)) return `${desc} <b>${bold}</b>`

		return desc.replaceAll(bold, `<b>${bold}</b>`)
	}

	const sanitizedHTML = () => {
		if (!formatTextToHtml()) return

		const sanitized = { __html: formatTextToHtml() }
		return sanitized
	}

	const getIcon = (obj: ModalTitle): FC<any> => {
		const { icon } = obj

		switch (icon as PredefinedModalIcons) {
			case 'plus':
				return Plus
			case 'checkmark':
				return RoundCheckmark
			case 'trash':
				return TrashIcon
			case 'warning':
				return WarningIcon
			case 'edit':
				return PenIcon
			default:
				if (typeof icon === 'function') return icon
		}
	}

	const TitleIcon = getIcon(title)

	return (
		<Dialog
			BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
			style={{ '--width': `${width}px` } as CSSProperties}
			open={open ?? false}
			PaperProps={{ style }}
			classes={{ root: 'modal', paper: clsx('modal__paper', { [customClass]: customClass }) }}
			onClose={onClose}
			transitionDuration={transitionDuration ?? 200}
		>
			<div className='modal--wrapper'>
				<div className='modal__header'>
					<div className='modal__header--title'>
						{TitleIcon && (
							<TitleIcon
								style={{ '--title-icon-color': title?.iconColor }}
								className='modal__header--title-icon'
							/>
						)}
						<h2>{title.text}</h2>
					</div>

					{onClose && (
						<div className='modal__header--close'>
							<IconButtonElement onClick={onClose} size='small'>
								<CloseIcon />
							</IconButtonElement>
						</div>
					)}
				</div>

				<div className='modal__content'>
					{sanitizedHTML() && (
						// biome-ignore lint/security/noDangerouslySetInnerHtml: The HTML content is sanitized and formatted before being set
						<p className='modal__content--text' dangerouslySetInnerHTML={sanitizedHTML()} />
					)}

					{children}

					{notes.length > 0 && (
						<ul className='modal__content--notes'>
							{notes.map((note) => (
								<li key={note}>{note}</li>
							))}
						</ul>
					)}
				</div>

				{!isObjEmpty(buttonProps) && (
					<div className='modal__ctas'>
						{!!cancelBtnProps && <ButtonElement {...cancelBtnProps}>{cancelBtnProps.text}</ButtonElement>}

						<ButtonElement scaleOnTap variant={cofirmBtnProps.variant ?? 'secondary'} {...cofirmBtnProps}>
							{cofirmBtnProps.text}
						</ButtonElement>
					</div>
				)}
			</div>
		</Dialog>
	)
}
