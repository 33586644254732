export const defaultDoughnutProps = (moreThanOneItem: boolean) => {
	return {
		borderColor: 'transparent',
		borderWidth: 0,
		spacing: moreThanOneItem ? 7 : 0,
		borderRadius: moreThanOneItem ? 2 : 0,
	}
}

export const defaultLineGraphTypography = {
	color: '#868686',
	font: {
		family: "'Bai Jamjuree', 'san-serif'",
		size: 12,
	},
}
