import type React from 'react'
import type { FC } from 'react'
import './StyledSeperatorText.scss'
import { FlexContainer } from '../FlexContainer/FlexContainer'

type StyledSeperatorTextProps = {
	children: React.ReactNode
}

export const StyledSeperatorText: FC<StyledSeperatorTextProps> = ({ children }) => {
	return (
		<FlexContainer className='styled-seperator' gap='var(--s-xxs)' align='center'>
			<div className='seperator' />

			<div className='styled-seperator__text'>{children}</div>
			<div className='seperator' />
		</FlexContainer>
	)
}
